<template>
  <div id="ChangeProject" v-loading="loading">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <!-- 头部选择 box -->
      <div class="bt">
        <!-- 标题 -->
        <div class="headline">
          <!-- <span class="head_one">已有项目/</span><span class="head_two">项目信息修改</span> -->
          <el-page-header @back="judgeDisplay">
            <span slot="title">{{ $store.state.lang == 'cn' ? '返回' : 'Back' }}</span>
          </el-page-header>
          <div class="projectName">
            {{ Program.program.project_name }}
          </div>
          <div class="img" @click="isShowChangeName">
            <img src="https://newstore.vynior.com/edit-pen%402x.png" alt="" />
          </div>
        </div>
        <button class="bt-button" @click="issaveProjectName">{{ $t(`message.Project.SaveAs`) }}</button>
        <!-- <div class="csxc">
        <img src="https://newstore.vynior.com/cxsc.png" alt="" @click="tip_replace = 1" />
      </div> -->
        <div class="Cancel" v-show="0">
          <span>取消</span>
        </div>
        <div class="download" @click="downFile" v-if="0">
          <span> 下载 </span>
        </div>
        <div class="complete" @click="_complete" v-if="0">
          <span> 完成 </span>
        </div>
      </div>

      <!-- 产品操作区域 -->
      <div class="editProduct">
        <div class="addProduct" @click="addProduct" v-show="$store.state.lang == 'cn'">
          <img src="https://newstore.vynior.com/xinzengcc.png" alt="" />
        </div>
        <div class="addProduct" @click="addProduct" v-show="$store.state.lang == 'en'">
          <img
            src="https://newstore.vynior.com/addproducts.png
"
            alt="" />
        </div>
        <div class="importData">
          <el-tooltip :open-delay="500" class="item" effect="dark" placement="bottom" popper-class="back_grounds">
            <div slot="content">
              {{ $t(`message.Project.new.Importhint`) }}
            </div>
            <button @click="tip_replace = 1">{{ $t(`message.Project.new.Import`) }}</button>
          </el-tooltip>
        </div>
        <div class="edit" v-show="isDisable == true">
          <button @click="amendMethod">{{ $t(`message.Project.new.Edit`) }}</button>
        </div>
        <!-- <div class="edit" v-show="isDisable == false">
        <button @click="CancleEdit">取消</button>
      </div> -->
        <div class="editChange" v-show="isDisable == false">
          <span class="editz">{{ $t(`message.Project.new.Editing`) }}</span>
        </div>
        <div class="save">
          <button @click="JudgeNum" v-show="0">保存</button>
        </div>
        <div class="productCategory">
          <div class="category">{{ $t(`message.Project.new.Category`) }}</div>
          <div class="categoryValue">{{ $t(`message.Project.new.Suncare`) }}</div>
        </div>
      </div>

      <!-- 项目内容 -->
      <div class="product-detail">
        <!-- 产品删除与复制 -->
        <div class="copyANDdelete">
          <div class="Copy" v-show="isDisable == false" @click="CopyMethod">{{ $t(`message.Project.new.Copy`) }}</div>
          <el-tooltip
            :open-delay="500"
            class="item"
            effect="dark"
            :content="$t(`message.Project.NotCopy`)"
            placement="bottom"
            popper-class="back_grounds">
            <div class="Copys" v-show="isDisable == true">{{ $t(`message.Project.new.Copy`) }}</div>
          </el-tooltip>
          <div class="Delete" @click="DeleteProduct" :class="isDisable == true ? 'addDelete' : ''">{{ $t(`message.Project.new.Delete`) }}</div>

          <div class="SaveProducts" @click="JudgeNum" v-show="isDisable != true">{{ $t(`message.Project.new.Save`) }}</div>
          <div class="CancelEDIT" @click="CancleEdit" v-show="isDisable != true">{{ $t(`message.Project.new.CancelEdit`) }}</div>
          <div class="SenseValue" :class="isDisable == true ? 'SenseValues' : ''">
            <div class="title">{{ $t(`message.Project.new.SensoryData`) }}</div>
            <div class="img">
              <img src="https://newstore.vynior.com/bingo.png" alt="" />
            </div>
            <div class="condition">{{ $t(`message.Project.new.Loaded`) }}</div>
          </div>
          <div class="instrument">
            <div class="title">{{ $t(`message.Project.new.InstrumentalData`) }}</div>
            <div class="condition">{{ $t(`message.Project.new.ComingSoon`) }}</div>
          </div>
        </div>
        <div class="content_box" ref="scrollRef">
          <!-- 项目标题  cn-->
          <div class="product-header" v-show="$store.state.lang == 'cn'">
            <div class="allCheck">
              <el-checkbox v-model="allChecked"></el-checkbox>
            </div>
            <div
              class="pd_Name"
              v-for="(item, index) in ProductName"
              :key="index"
              :class="[index == 0 ? 'CancelFlex' : '', index == 0 ? 'Title' : '', index > 11 ? 'changeColor' : '']">
              <span class="MustReat" v-show="index == 0">*</span>
              <div class="itemName">{{ item }}</div>
            </div>
          </div>
          <!-- 项目标题 en-->
          <div class="product-header" v-show="$store.state.lang == 'en'">
            <div class="allCheck">
              <el-checkbox v-model="allChecked"></el-checkbox>
            </div>
            <div
              class="pd_Name"
              v-for="(item, index) in enProductName"
              :key="index"
              :class="[index == 0 ? 'CancelFlex' : '', index == 0 ? 'Title' : '', index > 11 ? 'changeColor' : '']">
              <span class="MustReat" v-show="index == 0">*</span>
              <div class="itemName">{{ item }}</div>
            </div>
          </div>
          <!-- 项目信息 -->
          <div class="product-box">
            <div
              class="product-message"
              v-for="(item, index) in Program.products"
              :key="index"
              :class="[index == Program.products.length - 1 ? 'endBorder' : '']">
              <el-checkbox v-model="CheckedArr[index]"></el-checkbox>
              <div class="ProductData" v-for="(ite, inde) in item" :key="inde" :class="[inde == 0 ? 'TitelId' : '']">
                <div
                  class="item"
                  :class="[
                    inde > 11 && !judge.test(ite) ? 'Warning' : '',
                    isDisable ? 'CancelBorder' : '',
                    index == repetitionIndex[1] && inde == 0 ? 'Warning' : '',
                  ]">
                  <input
                    v-if="inde != 4 && inde != 5 && inde != 8"
                    type="text"
                    v-model="item[inde]"
                    :disabled="isDisable"
                    @blur="setTowNum(index, inde)" />

                  <el-form ref="form">
                    <el-form-item v-if="inde == 4" :class="isDisable == true ? 'noneIcon' : ''">
                      <el-select v-model="item[4]" :placeholder="$t(`message.Project.Select2`)" :disabled="isDisable">
                        <el-option :label="$t(`message.Project.None`)" :value="$t(`message.Project.None`)"></el-option>
                        <el-option :label="$t(`message.Project.Prototype`)" :value="$t(`message.Project.Prototype`)"></el-option>
                        <el-option :label="$t(`message.Project.Market`)" :value="$t(`message.Project.Market`)"></el-option>
                        <el-option :label="$t(`message.Project.Benchmark`)" :value="$t(`message.Project.Benchmark`)"></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item v-if="inde == 5" :class="isDisable == true ? 'noneIcon' : ''">
                      <el-select v-model="item[5]" :placeholder="$t(`message.Project.Select`)" :disabled="isDisable">
                        <el-option :label="$t(`message.Project.None`)" :value="$t(`message.Project.None`)"></el-option>
                        <el-option :label="$t(`message.Project.Cream`)" :value="$t(`message.Project.Cream`)"></el-option>
                        <el-option :label="$t(`message.Project.Lotion`)" :value="$t(`message.Project.Lotion`)"></el-option>
                        <el-option :label="$t(`message.Project.Gel`)" :value="$t(`message.Project.Gel`)"></el-option>
                        <el-option :label="$t(`message.Project.Oil`)" :value="$t(`message.Project.Oil`)"></el-option>
                        <el-option :label="$t(`message.Project.Other`)" :value="$t(`message.Project.Other`)"></el-option>
                        <el-option :label="$t(`message.Project.Smearmask`)" :value="$t(`message.Project.Smearmask`)"></el-option>
                        <el-option :label="$t(`message.Project.Sheetmask`)" :value="$t(`message.Project.Sheetmask`)"></el-option>
                        <el-option :label="$t(`message.Project.Toner`)" :value="$t(`message.Project.Toner`)"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item v-if="inde == 8" :class="isDisable == true ? 'noneIcon' : ''">
                      <el-select v-model="item[8]" :placeholder="$t(`message.Project.Select3`)" :disabled="isDisable">
                        <el-option :label="$t(`message.Project.None`)" :value="$t(`message.Project.None`)"></el-option>
                        <el-option :label="$t(`message.Project.Chemical`)" :value="$t(`message.Project.Chemical`)"></el-option>
                        <el-option :label="$t(`message.Project.Physical`)" :value="$t(`message.Project.Physical`)"></el-option>
                        <el-option :label="$t(`message.Project.Hybird`)" :value="$t(`message.Project.Hybird`)"></el-option>
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item v-if="inde == 9" :class="isDisable == true ? 'noneIcon' : ''">
                      <el-select v-model="item[9]" placeholder="请选择产品颜色" :disabled="isDisable">
                        <el-option label="白色" value="白色"></el-option>
                        <el-option label="黑色" value="黑色"></el-option>
                      </el-select>
                    </el-form-item> -->
                  </el-form>
                </div>
              </div>
            </div>
          </div>

          <!-- 当前页面的空状态 -->
          <div class="null_page" v-show="0">
            <div class="null_img">
              <img src="https://newstore.vynior.com/Group%2033553%402x.png" alt="" />
            </div>
            <div class="null_message">暂无新建产信息哦</div>
          </div>
        </div>
      </div>
      <div class="backSure" v-show="backS">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              {{ $t(`message.Project.NotSaved`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="backS = false" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Project.PleaseSave`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="backS = false"> {{ $t(`message.Project.Cancel`) }}</span>
              <span class="sure" @click="backS = false"> {{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 提醒填写产品信息ui -->
      <div class="notarize_deletes" v-show="Once_sure">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              {{ $t(`message.Message.Missing`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sure = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Message.FIELDS`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sure = 0">{{ $t(`message.Message.Cancel`) }}</span>
              <span class="sure" @click="Once_sure = 0">{{ $t(`message.Message.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 导入错误操作 -->
      <div class="notarize_delete" v-show="Once_import">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              属性数据导入失败!
              <img :class="Once_import == 1 ? 'notarize_import' : ''" src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_import = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              请检查导入格式以及模板是否正确
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_import = 0">取消</span>
              <span class="sure" @click="Once_import = 0">确定</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 未保存,提醒ui -->
      <div class="notarize_save" v-show="Once_sureSave">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureSave == 1 ? 'changeMargan' : ''">
              您还有未保存的产品信息!
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sureSave = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              请先进行保存或取消,方可进行其他操作
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sureSave = 0">取消</span>
              <span class="sure" @click="Once_sureSave = 0">确定</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 空的项目信息,点击新建产品弹窗 -->
      <div class="notarize_deletep" v-show="Once_sureCreate">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureCreate == 1 ? 'changeColor' : ''">
              是否填写完成项目信息?
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sureCreate = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              填写后,方可进行新建产品操作
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sureCreate = 0">{{ $t(`message.Message.Cancel`) }}</span>
              <span class="sure" @click="Once_sureCreate = 0">{{ $t(`message.Message.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 删除产品提示ui -->
      <div class="notarize_delete" v-show="Once_sureDelete">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureDelete == 1 ? 'changeMargin' : ''">
              {{ $t(`message.Message.confirmDeletes`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sureDelete = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Message.DeletsHint`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sureDelete = 0"> {{ $t(`message.Message.Cancel`) }}</span>
              <span class="sure" @click="Delete_sure_button">{{ $t(`message.Message.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--确认修改产品品类操作 -->
      <div class="notarize_delete" v-show="Once_change">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              确定修改产品品类吗?
              <img
                :class="Once_change == 1 ? 'notarize_Cancle_x' : ''"
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="cancel_change_category" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              修改后，产品信息内的品类也将修改
            </div>
            <div class="line_3">
              <span class="cancle" @click="cancel_change_category">取消</span>
              <span class="sure" @click="change_sure_button" :class="Once_change == 1 ? 'notarize_Cancle' : ''">确定</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 新建项目的导入提示框 -->
      <!--导入数据提示ui -->
      <div class="import_tip" v-show="upload_success">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              新建项目
              <img src="https://newstore.vynior.com/cancelss.png" alt="" @click="upload_success = false" />
            </div>
            <div class="line_2">
              <!-- 上传文件 -->
              <div class="import_box">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreviews"
                  :on-remove="handleRemoves"
                  :before-remove="beforeRemoves"
                  :on-exceed="handleExceeds"
                  :file-list="fileLists"
                  multiple
                  :http-request="uploadFiles"
                  :show-file-list="false"
                  :before-upload="beforeUpload">
                  <img src="https://newstore.vynior.com/uploads.png" alt="" class="logo" />
                  <div class="el-upload__text">将xlsx文件拖到此处，或点击上传文件</div>
                  <img src="https://newstore.vynior.com/scmm.png" alt="" class="button" />
                </el-upload>
              </div>
              <!-- 下载模板 -->
              <div class="download_box">
                <img src="https://newstore.vynior.com/dlmm.png" class="logo" />
                <span>如果您是首次使用，请先下载FAST模板</span>
                <img src="https://newstore.vynior.com/xzmm.png" class="down_load" @click="downFile" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 重新上传 -->
      <el-upload
        :http-request="uploadFiles"
        class="upload-demo"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-preview="handlePreviews"
        :on-remove="handleRemoves"
        :before-remove="beforeRemoves"
        multiple
        :on-exceed="handleExceeds"
        :file-list="fileLists"
        :show-file-list="false">
        <el-button style="display: none" size="small" type="primary" id="ups">点击上传</el-button>
      </el-upload>

      <!--导入数据提示ui -->
      <div class="notarize_import" v-show="tip_replace">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              {{ $t(`message.Project.Reports`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="tip_replace = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Project.ReDetail`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="tip_replace = 0">{{ $t(`message.Project.Cancel`) }}</span>
              <span class="sure" @click="sure_import">{{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--修改项目编号 -->
      <div class="ChangeProjectNumber" v-show="isAmendNumber">
        <div class="content">
          <div class="line1" :class="this.$store.state.lang == 'cn' ? 'margins' : ''">
            <div class="title">{{ $t(`message.Project.new.ProjectNo`) }}</div>
            <div class="img" @click="isAmendNumber = false">
              <img src="https://newstore.vynior.com/cancelss.png" alt="" />
            </div>
          </div>
          <div class="line2">
            <input type="text" v-model="amendProjectValue" />
          </div>
          <div class="line3">
            <button class="cancel" @click="isAmendNumber = false">{{ $t(`message.Project.Cancel`) }}</button>
            <button class="confirm" @click="amendProject(1)">{{ $t(`message.Project.new.Save`) }}</button>
          </div>
        </div>
      </div>

      <!-- 另存为页面 -->
      <div class="ChangeProjectNumber" v-if="issaveTitle">
        <div class="content">
          <div class="line1">
            <div class="title">{{ $t(`message.Project.AsProjectNo`) }}</div>
            <div class="img" @click="issaveTitle = false">
              <img src="https://newstore.vynior.com/cancelss.png" alt="" />
            </div>
          </div>
          <div class="line2">
            <input class="input2" placeholder="请输入另存为的项目编号" v-model="saveProjectValue" />
          </div>
          <div class="line3">
            <button class="cancel" @click="issaveTitle = false">{{ $t(`message.Project.Cancel`) }}</button>
            <button class="confirm" @click="amendProject(2)">{{ $t(`message.Project.new.Save`) }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HttpClient } from '@/utils/http';
import { message } from '@/utils/resetMessage';
import axios from 'axios';
export default {
  name: 'ChangeProject',
  data() {
    return {
      backS: false, //左上角返回弹窗
      loading: true, //过渡动画
      resourceProject_name: '', //存储每次修改前最初的数据
      resourceCategory: '', //存储每次修改前最初的数据
      resourceProduct: [], //存储每次修改前最初的数据.
      downLoadAddress: '', //下载文件的地址
      xlsx_name: '', //上传文件返回来的 文件名称(下载文件用)
      repetitionIndex: [], //重复的产品名字下标
      ProductNull: [], //新建产品的数组model
      CheckedArr: [], //产品多选框数组
      allChecked: false, //全选按钮
      isDisable: false,
      ProductName: [
        '产品名称',
        '品牌',
        '生产日期',
        '生产批号',
        '产品来源',
        '产品性状',
        'SPF值',
        'PA指数',
        '物理化学防晒',
        '产品颜色',
        '上市国家',
        '备注',
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度(2圈)',
        '增白程度(5圈)',
        '涂抹难易程度',
        '泛白程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '增白程度(即时)',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤水感(即时)',
        '皮肤油感(即时)',
        '增白程度(2分钟)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤水感(2分钟)',
        '皮肤油感(2分钟)',
        '增白程度(5分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '皮肤水感(5分钟)',
        '皮肤油感(5分钟)',
        '总体吸收速度',
      ], //产品维度
      enProductName: [
        'Product Name',
        'Brand',
        'Date of Production',
        'Batch No.',
        'Source',
        'Phase',
        'SPF value',
        'PA rating',
        'Formulation System',
        'Color',
        'Country for listing',
        'Note',
        ' Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability(2rubs)',
        'Whitening(5rubs)',
        'Spreadability(10rubs)',
        'Chalky',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Whitening(instant)',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Watery(instant)',
        'Oiliness(instant)',
        'Whitening(2min)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Watery(2min)',
        'Oiliness(2min)',
        'Whitening(5min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Watery(5min)',
        'Oiliness(5min)',
        'Speed of absorption',
      ],
      judge: new RegExp(/^([0-9]|1[0-5])(\.[0-9])?$/), //input框输入0-15正则表达式
      amendProjectValue: '', //修改的项目名称
      saveProjectValue: null, //另存为项目名称
      isAmendNumber: false, //修改窗口显示
      issaveTitle: false, //另存为窗口显示
      fileNames: '',
      tip_replace: 0, //导入覆盖的提示ui
      import_name: '', //标记是从哪一个页面进来的.
      products_val: [],
      back_val: [], //res重新导入返回信息
      upload_success: 0, //重新导入
      title: '', //项目的name
      id_list: [], //存储删除的产品id数组
      import_val: [], //导入的。
      fileLists: [],
      down_name: '',
      copy_data: [],

      before_id: '',
      panel_val: '', //导入数据的名称
      not_import: 1,
      pro_name: '', //项目名称
      fileList: [],
      change_sure: 0, //确认 更改category
      Once_change: 0, //控制 确认修改产品品类 的ui
      old_category: '', //存储项目最初的产品品类
      new_category: '', //存储最新的产品品类
      category_arrs: [], //存储用户的产品品类
      Res_Pd: [], //
      Res_ID: [], //存储保存返回来的信息
      program_name: [], //存放项目的id,用于对比是否唯一
      haveSave: -1, //存储有""保存"按钮的下标
      max_border: 0, //最外层的border控制器
      select_classification: '',
      product_id: [], //存放产品id
      Program: { program: { project_name: '' } }, //监听接收 修改项目的具体信息
      // 确认取消操作
      Once_Cancle: 0,
      // 导入失败
      Once_import: 0,
      // 未填写完整
      Once_sure: 0,
      //未保存信息
      Once_sureSave: 0,
      //项目信息不完成
      Once_sureCreate: 0,
      // 删除产品信息
      Once_sureDelete: 0,
      // 存储删除产品的下标
      cz_index: -1,
      product_index: -1, //记录下标
      product_cfn_warn: 0, //控制产品表格警告
      product_name_warn: 0, //控制产品name表格警告
      // 警告消息
      warn_fist: 0,
      warn_fist2: 0,
      add_border: -1,
      project_id: 'FS-066',
      product_val: '防晒霜',
      craete_time: '', //项目的创建时间

      Res_program: {},
      Products: [], //将接收的数据拆分为产品和项目信息

      category_arr: [
        '洗面奶',
        '爽肤水',
        '乳液',
        '眼霜',
        '面部精华',
        '面霜',
        '防晒霜',
        '贴片面膜',
        '妆前乳',
        '洗发水',
        '护发素',
        '头皮精华',
        '沐浴露',
        '身体乳',
        '护手霜',
        '洁面油',
        '洁面泡沫',
        '涂抹式面膜',
        '气垫',
        '卸妆油水膏',
        '粉底液',
        '口红',
        '固色洗发水',
        '发型固发',
        '染发',
        '护发精油',
        '头皮磨砂膏',
        '磨砂膏',
        '身体护肤油',
        '身体乳（滚珠）',
        '香皂',
      ],
      Products_model: {
        disType: true, //默认不可修改
        // product_id: "X3tucj8LYuBo638gq6pQOpC1", //产品_Id
        // project_id: "lRN7rsBiB2DMMONmDV7CClOQ", //项目_Id
        // project_name: "FS-066", //项目编号

        classification_Arr: [
          { classification_val: '开发原型', order: '' },
          { classification_val: '市售产品', order: '' },
          { classification_val: '竞争品牌', order: '' },
        ], //产品品类

        color_Arr: [
          { colorval: '白色' }, //产品颜色
          { colorval: '黄色' }, //产品颜色
          { colorval: '透明' }, //产品颜色
          { colorval: '粉色' }, //产品颜色
        ],

        quality_Arr: [{ quality_val: '固态' }, { quality_val: '液态' }, { quality_val: '气雾' }, { quality_val: '粉末' }], //质地

        cz1: '修改',
        cz2: '复制',
        cz3: '删除',
      },
      Products_model_null: {
        cz1: '保存',
        cz2: '取消',
        cz3: '',
        disType: false, //默认可以修改
        // product_id: "X3tucj8LYuBo638gq6pQOpC1", //产品_Id
        // project_id: "lRN7rsBiB2DMMONmDV7CClOQ", //项目_Id
        // project_name: "FS-066", //项目编号
        name: '',
        classification: '',
        classification_Arr: [
          { classification_val: '开发原型', order: '' },
          { classification_val: '市售产品', order: '' },
          { classification_val: '竞争品牌', order: '' },
        ], //产品品类
        category: '', //产品分类
        product_number: '', //产品编号
        color: '',
        color_Arr: [
          { colorval: '白色' }, //产品颜色
          { colorval: '黄色' }, //产品颜色
          { colorval: '透明' }, //产品颜色
          { colorval: '粉色' }, //产品颜色
        ],
        product_place: '', //产地
        brand: '', //品牌
        quality: '',
        quality_Arr: [{ quality_val: '固态' }, { quality_val: '液态' }, { quality_val: '气雾' }, { quality_val: '粉末' }], //质地

        remark: '', //备注
        _id: '', //产品id
      },
      old_Products: [], //产品名称对比保留
      cvalue: 0,
      val: '修改',
      vals: '保存',
      val_copy: '复制',
      val_cancle: '取消',
      cancle_val: [], //取消前的存值
      val_delete: '删除',

      disType: true, //默认禁止修改
      kong: 0,
      teshu: 0,
      notOnly: 0,
      max: 0,
    };
  },
  watch: {
    // 监听 仓库汇总的 是否离开组件的状态
    // '$store.state.changeRoute':{
    //   handler(){
    //    if()
    //   }
    // },
    //监视 项目信息中的 select
    'Res_program.category': {
      handler(newVal) {
        this.new_category = newVal;
        if (this.old_category != newVal && newVal != '') {
          // this.Once_change = 1;
          console.log(this.Once_change);
          console.log(this.old_category);
        }
      },
    },
    change_sure: {
      handler(newv) {
        if (newv == 1) {
          this.old_category = this.new_category;
          console.log(this.new_category);
          console.log('新品类');
          this.Products_model_null.category = this.new_category;

          for (let index = 0; index < this.Program.products.length; index++) {
            this.Program.products[index].category = this.new_category;
          }
        } else {
          this.Res_program.category = this.old_category;
        }
        this.change_sure = 0;
      },
    },
    panel_val: {
      handler(newv, oldv) {
        if (newv != oldv) {
          if (newv.length > 15) {
            this.not_import = 0;
            let tou = newv.slice(0, 7);
            let wei = newv.slice(-4);
            console.log(tou);
            console.log(wei);

            this.pro_name = tou + '...' + wei;
          } else {
            this.not_import = 0;
            this.pro_name = newv;
            // this.not_import = 1;
          }
        }
        if (newv == 'undefined') {
          this.not_import = 1;
        }
      },
    },
    //全选产品数据
    allChecked(newvalue) {
      if (newvalue == true) {
        for (let i = 0; i < this.CheckedArr.length; i++) {
          this.CheckedArr[i] = true;
        }
      } else {
        for (let i = 0; i < this.CheckedArr.length; i++) {
          this.CheckedArr[i] = false;
        }
      }
    },
    '$store.state.lang': {
      handler: function () {
        if (this.$store.state.lang == 'en') {
          this.Program.products.forEach((item) => {
            if (item[4] == '市售产品') {
              item[4] = 'In-market Product';
            } else if (item[4] == '开发原型') {
              item[4] = 'Prototype';
            } else if (item[4] == '竞争品牌') {
              item[4] = 'Benchmark';
            } else if (item[4] == '无') {
              item[4] = 'None';
            }
            switch (item[5]) {
              case '膏霜':
                item[5] = 'Cream';
                break;
              case '乳液':
                item[5] = 'Lotion';
                break;
              case '啫喱':
                item[5] = 'Gel';
                break;
              case '油类':
                item[5] = 'Oil';
                break;
              case '其他':
                item[5] = 'Other';
                break;
              case '涂抹式面膜':
                item[5] = 'Smear mask';
                break;
              case '贴片式面膜':
                item[5] = 'Sheet mask';
                break;
              case '水类':
                item[5] = 'Toner';
                break;
              case '无':
                item[5] = 'None';
                break;
            }
            switch (item[8]) {
              case '化学':
                item[8] = 'Chemical';
                break;
              case '物理':
                item[8] = 'Physical';
                break;
              case '物化结合':
                item[8] = 'Hybird';
                break;
              case '无':
                item[8] = 'None';
                break;
              default:
                break;
            }
          });
        } else {
          this.Program.products.forEach((item) => {
            if (item[4] == 'In-market Product') {
              item[4] = '市售产品';
            } else if (item[4] == 'Prototype') {
              item[4] = '开发原型';
            } else if (item[4] == 'Benchmark') {
              item[4] = '竞争品牌';
            } else if (item[4] == 'Benchmark') {
              item[4] = '竞争品牌';
            } else if (item[4] == 'None') {
              item[4] = '无';
            }
            switch (item[5]) {
              case 'Cream':
                item[5] = '膏霜';
                break;
              case 'Lotion':
                item[5] = '乳液';
                break;
              case 'Gel':
                item[5] = '啫喱';
                break;
              case 'Oil':
                item[5] = '油类';
                break;
              case 'Other':
                item[5] = '其他';
                break;
              case 'Smear mask':
                item[5] = '涂抹式面膜';
                break;
              case 'Sheet mask':
                item[5] = '贴片式面膜';
                break;
              case 'Toner':
                item[5] = '水类';
                break;
              case 'None':
                item[5] = '无';
                break;
            }
            switch (item[8]) {
              case 'Chemical':
                item[8] = '化学';
                break;
              case 'Physical':
                item[8] = '物理';
                break;
              case 'Hybird':
                item[8] = '物化结合';
                break;
              case 'None':
                item[8] = '无';
                break;
              default:
                break;
            }
          });
        }
      },
    },
  },
  methods: {
    //显示导入工具

    sure_import() {
      // this.upload_success = 1;
      this.tip_replace = 0;
      let upload = document.getElementById('ups');
      upload.click();
    },
    //下载模板1
    downFile() {
      let a = document.createElement('a');
      a.href = this.Program.program.panel_files; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
    },
    //返回判断
    judgeDisplay() {
      if (this.isDisable == true) {
        this.goBack();
      } else {
        this.backS = true;
      }
    },
    //返回
    goBack() {
      // console.log('back' + this.import_name);
      console.log('this.$store.state.backFrom :>> ', this.$store.state.backFrom);
      if (this.$store.state.backFrom == 1) {
        this.$EventBus.$emit('back_haveprojects', 'Haveproject');
      } else if (this.$store.state.backFrom == 2) {
        this.$EventBus.$emit('back_haveproject', 'Haveproject');
      }
    },
    //确认修改category 的按钮
    change_sure_button() {
      this.change_sure = 1;
      this.Once_change = 0;
    },

    //修改category 取消操作
    cancel_change_category() {
      this.Once_change = 0;
      this.Res_program.category = this.old_category;
    },

    //下载模板
    async downFile2() {
      console.log('this.fileNames :>> ', this.fileNames);
      let response = await axios.get(
        this.down_name,
        { responseType: 'blob' },
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        }
      );
      const blob = new Blob([response.data]);
      let a = document.createElement('a');
      // console.log(this.down_name);
      // console.log('this.Res_program.panel_name :>> ', this.Res_program.panel_name);
      let href = window.URL.createObjectURL(blob);
      a.href = href; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.download = this.fileNames;
      a.click();
      window.URL.revokeObjectURL(href);
    },

    // 判断是否有未保存
    judge_save() {
      for (let index = 0; index < this.Program.products.length; index++) {
        if (this.Program.products[index].cz1 == '保存') {
          // this.haveSave = index;
          // console.log("haveSave" + this.haveSave);
          this.Once_sureSave = 1;
          console.log('我执行了2');
        } else {
          this.Once_sureSave = 0;
          // this.max_border = 1;
          console.log('我执行了');
        }
      }
    },

    async changeType() {
      this.$EventBus.$emit('back_haveproject', 'Haveproject');
    },
    // 更改项目名称
    onBlur() {
      var judgeFn = new RegExp(/^[A-Za-z0-9-]{1,}$/g);
      if (this.project_id != '' && judgeFn.test(this.project_id)) {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success',
        });
        this.warn_fist = 0;
        this.warn_fist2 = 0;
      } else {
        this.warn_fist2 = 1;
        this.warn_fist = 0;
        this.$message.error('项目编号信息错误');
      }

      if (this.project_id.length > 20) {
        this.warn_fist = 1;
      }
    },
    onBlurs() {
      var judgeFn = new RegExp(/^\S.{0,30}\S$/); //判断空格
      if (this.product_val != '' && judgeFn.test(this.product_val)) {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success',
        });
      } else {
        this.$message.error('产品品类信息错误');
      }
    },
    // 处理时间
    setTime() {
      console.log(this.Res_program);
    },
    //回到顶部
    top_message() {
      // alert("123");
      if (this.$refs.scrollRef.scrollTop == 0) {
        this.$message({
          showClose: true,
          message: '当前已经是顶部信息',
          type: 'warning',
        });
      } else {
        this.$refs.scrollRef.scrollTop = 0;
      }
    },

    // 请求userid下的产品品类
    // 请求用户的产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      console.log(res);
      this.category_arrs = res.classification;
      console.log(this.category_arrs);
    },
    // 取消操作，返回已有项目
    back_1() {
      this.$emit('close');
      this.$EventBus.$emit('pageChange', 'HaveProject');
    },
    //导入小组组件1
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    async uploadFile(params) {
      const file = params.file;
      const form = new FormData();
      form.append('file', file);
      console.log(params);
      console.log(this.Program.program._id);
      form.append('program', this.Program.program._id);
      await HttpClient.post('program/panel_filesupload', form, {
        headers: { 'Conten-Type': 'multipart/form-data' },
      }).then((res) => {
        console.log(res);
        let name = res.name;
        if (res.name > 15) {
          // this.not_import = 0;
          let tou = res.name.slice(0, 7);
          let wei = res.name.slice(-4);
          console.log(tou);
          console.log(wei);
          console.log(name);
          this.pro_name = tou + '...' + wei;
          console.log(this.pro_name);
        } else {
          this.pro_name = res.name;
        }
      });
    },

    async not_imports() {
      this.not_import = 1;
      await HttpClient.post('program/delete_panel', {
        id: this.Program.program._id,
      }).then((res) => {
        console.log(res.message);
        if (res.message == 'success') {
          console.log('123123123123');
          this.Program.products = [];
          this.Res_program.project_name = '';
          this.Res_program.category = '';
          HttpClient.post('ctmproduct/alldelete', {
            productList: this.product_id,
          });
        }
      });
    },
    //导入小组组件2(使用中)
    aa(file) {
      console.log(file);
    },
    handleRemoves(file, fileList) {
      console.log(file, fileList);
    },
    handlePreviews(file) {
      console.log(file);
    },
    handleExceeds(files, fileList) {
      this.$message.warning(`当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

    beforeUpload(file) {
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ['xlsx']; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: '请上传正确格式的项目文件',
          type: 'error',
          duration: 2000,
        });
      }
      return suffixArray;
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.');
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return '';
      }
    },

    beforeRemoves(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    //note 产品导入替换
    async uploadFiles(params) {
      const file = params.file;
      const form = new FormData();
      form.append('file', file);
      console.log(params);
      form.append('sub', this.$store.state.userid);
      await HttpClient.post('program/programData_upload2', form, {
        headers: { 'Conten-Type': 'multipart/form-data' },
      }).then((res) => {
        console.log(res);
        console.log('res.project_name', res.project_name);
        // if(res.project_name){

        // }
        var status = res.status;
        this.down_name = res.downloadPath;
        //上传失败
        if (status == 1) {
          message.warning(res.message);
          return;
        } else {
          message({
            showClose: true,
            message: this.$t(`message.Message.Import`),
            type: 'success',
          });
          this.isDisable = false;
          this.Program.products = res.result; //导入新的产品替换
          this.Program.program.category = res.category;
          this.Program.program.project_name = res.project_name;
          this.Program.program.panel_name = res.fileName;
          this.xlsx_name = res.fileName;
          ////导入新增产品的值
          for (let index = 0; index < this.import_val[0].length; index++) {
            this.ProductNull.push('');
          }
          //导入产品前面的 check 值
          for (let index = 0; index < this.import_val.length; index++) {
            this.CheckedArr.push(false);
          }
        }
        if (res.name != '') {
          this.upload_success = false;

          this.pro_name = res.name;
        }
      });
    },
    //note 名字通过校验才可以执行此方法
    set_val() {
      //替换面包屑名称
      this.title = this.back_val.result.project_name;
      //处理导入文件的名称
      let name = this.back_val.name;
      if (name.length > 15) {
        let tou = this.back_val.name.slice(0, 7);
        let wei = this.back_val.name.slice(-4);
        console.log(tou);
        console.log(wei);
        console.log(name);
        this.pro_name = tou + '...' + wei;
        console.log(this.pro_name);
      } else {
        this.pro_name = this.back_val.name;
      }

      this.import_val = this.back_val.result;
      // this.Program.products = res.result.productArray;
      this.Res_program.project_name = this.back_val.result.project_name;
      this.Res_program.category = this.back_val.result.category;
      console.log(this.Program.products);

      // for (let index = 0; index < this.import_val.productArray.length; index++) {
      //   this.Program.products.push(
      //     JSON.parse(JSON.stringify(this.Products_model_null))
      //   );
      //   console.log(this.import_val);

      // }
      console.log(this.Program.products, '导入model');
      // 赋值
      for (let index = 0; index < this.Program.products.length; index++) {
        this.Program.products[index].name = this.import_val.productArray[index].name;
        this.Program.products[index].category = this.import_val.productArray[index].category;
        this.Program.products[index].color = this.import_val.productArray[index].color;
        this.Program.products[index].product_number = this.import_val.productArray[index].product_number;
        this.Program.products[index].product_place = this.import_val.productArray[index].product_place;
        this.Program.products[index].brand = this.import_val.productArray[index].brand;
        this.Program.products[index].quality = this.import_val.productArray[index].quality;
        this.Program.products[index].remark = this.import_val.productArray[index].remark;
        this.Program.products[index].classification = this.import_val.productArray[index].source;
        this.Program.products[index].spf = this.import_val.productArray[index].spf;
      }
      this.old_Products = [];
      console.log(this.Program.products, '导入数据');
      this.upload_success = 0;

      this.$message({
        type: 'success',
        message: '导入数据成功!',
      });
    },

    //全部保存
    allSave() {
      console.log(this.Program.products);
      for (let index = 0; index < this.Program.products.length; index++) {
        if (this.Program.products[index].cz1 == '保存') {
          this.changes(this.Program.products[index].cz1, index);
        }
      }
    },

    //获取option的值
    async getOptions() {
      let response = await HttpClient.post('productinfo/getOptions');
      console.log('options-->', response);
      this.$set(this.Products_model_null, 'color_Arr', response.color);
      this.$set(this.Products_model_null, 'quality_Arr', response.quality);
      this.$set(this.Products_model_null, 'classification_Arr', response.classification);
      this.$set(this.Products_model, 'color_Arr', response.color);
      this.$set(this.Products_model, 'quality_Arr', response.quality);
      this.$set(this.Products_model, 'classification_Arr', response.classification);
      // this.$set(this.import_model, "color_Arr", response.color);
      // this.$set(this.import_model, "quality_Arr", response.quality);
      // this.$set(this.import_model, "classification_Arr", response.classification);
    },
    //给修改项目名称赋值
    isShowChangeName() {
      this.isAmendNumber = true;
      // console.log('赋值 :>> ', this.Result.project_name);
      this.amendProjectValue = JSON.parse(JSON.stringify(this.Program.program.project_name));
    },
    //另存为逻辑
    async issaveProjectName() {
      this.repetitionIndex = []; //重置重复产品名字下标
      // --->校验数据维度0-15;
      let sum = 0;
      let judege = new RegExp(/^([0-9]|1[0-5])(\.[0-9])?$/);
      for (let i = 0; i < this.Program.products.length; i++) {
        for (let j = 12; j < 53; j++) {
          if (!judege.test(this.Program.products[i][j])) {
            sum += 1;

            console.log('this.Program.products[i][j] :>> ', this.Program.products[i][j] + '不符合规矩');
            console.log('this.Program.products[i][j] :>> ', typeof this.Program.products[i][j]);
            message({
              type: 'warning',
              message: this.$t(`message.Project.new.informationError`),
              showClose: true,
            });
          }
          // }
        }

        //判断名字
        let arrName = [];
        //将所有名字导入进新数组
        this.Program.products.forEach((item) => {
          arrName.push(item[0]);
        });
        // console.log('arrName :>> ', arrName);
        //判断重复
        arrName.map((val, index) => {
          if (arrName.indexOf(val) != arrName.lastIndexOf(val)) {
            console.log('重复项的下标:' + index);
            console.log('重复项的值:' + val);
            this.repetitionIndex.push(index);
            message({
              type: 'warning',
              message: '一个项目内产品名称不可重复,请进行修改!',
              showClose: true,
            });
            sum += 1;
          }
        });
        // console.log(' this.repetitionIndex :>> ', this.repetitionIndex);
        //判断名字为空
        arrName.forEach((item) => {
          if (item == '') {
            sum += 1;
            this.Once_sure = true;
          }
        });
      }
      if (sum == 0) {
        //如果有重复跳出弹框  如果没有直接保存成功
        let res = await HttpClient.post('program/program_save1', {
          updates: {
            project_name: this.Program.program.project_name,
            category: this.Program.program.category,
            status: '已保存',
            sub: this.$store.state.userid,
            panel_name: this.xlsx_name,
            // _id: this.Program.program._id,
          },
          productDataList: this.Program.products,
        });
        console.log('res :>> ', res);
        if (res.status == 1) {
          message({
            type: 'warning',
            message: this.$t(`message.Message.ProjectNo`),
            showClose: true,
          });
          this.issaveTitle = true;
        } else {
          // this.goBack();
          this.$EventBus.$emit('send_success', 'Haveproject');
          this.issaveTitle = false;
          message({
            type: 'success',
            message: this.$t(`message.Project.SaveAS`),
            showClose: true,
          });
        }
      }
      // console.log('赋值 :>> ', this.Result.project_name);
    },

    //修改项目名字的确认按钮
    //!  修改逻辑
    async amendProject(e) {
      if (e == 1) {
        if (this.amendProjectValue == '') {
          message({
            type: 'warning',
            message: this.$t(`message.Message.emptyNo`),
            showClose: true,
          });
        } else {
          this.Program.program.project_name = JSON.parse(JSON.stringify(this.amendProjectValue));
          this.isAmendNumber = false;
        }
      } else {
        if (this.saveProjectValue == '') {
          message({
            type: 'warning',
            message: '项目编号不可为空,请修改后再次上传! ',
            showClose: true,
          });
        } else {
          // ! 判断是否外面有这个名字 如果有提示用户修改，如果没有则保存成功，直接跳出
          //判断是否是草稿箱内的数据(新建的的数据)
          let res = await HttpClient.post('program/program_save1', {
            updates: {
              project_name: this.saveProjectValue,
              category: this.Program.program.category,
              status: '已保存',
              sub: this.$store.state.userid,
              panel_name: this.xlsx_name,
              // _id: this.Program.program._id,
            },
            productDataList: this.Program.products,
          });
          console.log('res :>> ', res);
          if (res.status == 1) {
            message({
              type: 'warning',
              message: '项目名重复!',
              showClose: true,
            });
          } else {
            this.$EventBus.$emit('send_success', 'Haveproject');
            this.issaveTitle = false;
            message({
              type: 'success',
              message: this.$t(`message.Project.SaveAS`),
              showClose: true,
            });
          }
        }
      }
    },
    //复制产品
    CopyMethod() {
      if (this.isDisable == true) {
        message({
          type: 'warning',
          message: '请在编辑状态下复制产品!',
          showClose: true,
        });
      } else {
        let index = 0;
        this.CheckedArr.forEach((item) => {
          if (item == true) {
            index++;
          }
        });
        if (index == 1) {
          let i = this.CheckedArr.indexOf(true);

          console.log('i :>> ', i);

          let temporary = JSON.parse(JSON.stringify(this.Program.products[i]));
          this.Program.products.splice(i, 0, temporary);
        } else if (index == 0) {
          message({
            type: 'warning',
            message: this.$t(`message.Message.copySelect`),
            showClose: true,
          });
        } else {
          message({
            type: 'warning',
            message: this.$t(`message.Project.onlyOne`),
            showClose: true,
          });
        }
      }
    },
    //删除产品信息
    DeleteProduct() {
      if (this.CheckedArr.indexOf(true) == -1) {
        message({
          type: 'warning',
          message: this.$t(`message.Message.Atleast`),
          showClose: true,
        });
      } else {
        this.CheckedArr.forEach((item, index) => {
          this.Once_sureDelete = 1;
          if (item == true) {
            console.log('index :>> ', index);
            console.log('this.Program.products[index][0] :>> ', this.Program.products[index][0]);
          }
        });
      }
    },
    //确认框 确认删除
    Delete_sure_button() {
      let arr = [];
      this.CheckedArr.forEach((item, index) => {
        if (item == true) {
          arr.push(index);
        }
      });
      // arr=reverse(arr)
      for (let index = arr.length - 1; index >= 0; index--) {
        console.log(arr[index]);
        this.Program.products.splice(arr[index], 1);
        this.CheckedArr.splice(arr[index], 1);
      }

      this.Once_sureDelete = 0;
    },
    //新建产品
    addProduct() {
      if (this.isDisable == true) {
        message({
          type: 'warning',
          message: this.$t(`message.Project.NotAdd`),
          showClose: true,
        });
      } else {
        this.Program.products.push(JSON.parse(JSON.stringify(this.ProductNull)));
        this.$nextTick(() => {
          let scrollEl = this.$refs.scrollRef;
          scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
        });
      }
    },
    //保存产品信息
    async JudgeNum() {
      this.CheckedArr.forEach((item, index) => {
        this.$set(this.CheckedArr, index, false);
      });
      // if (this.isDisable == true) {
      //   message({
      //     type: 'warning',
      //     message: '当前产品信息以保存!',
      //     showClose: true,
      //   });
      // } else {
      this.repetitionIndex = []; //重置重复产品名字下标
      // --->校验数据维度0-15;
      let sum = 0;
      let judege = new RegExp(/^([0-9]|1[0-5])(\.[0-9])?$/);
      for (let i = 0; i < this.Program.products.length; i++) {
        for (let j = 12; j < 53; j++) {
          if (!judege.test(this.Program.products[i][j])) {
            sum += 1;

            console.log('this.Program.products[i][j] :>> ', this.Program.products[i][j] + '不符合规矩');
            console.log('this.Program.products[i][j] :>> ', typeof this.Program.products[i][j]);
            message({
              type: 'warning',
              message: this.$t(`message.Project.new.informationError`),
              showClose: true,
            });
          }
          // }
        }

        //判断名字
        let arrName = [];
        //将所有名字导入进新数组
        this.Program.products.forEach((item) => {
          arrName.push(item[0]);
        });
        // console.log('arrName :>> ', arrName);
        //判断重复
        arrName.map((val, index) => {
          if (arrName.indexOf(val) != arrName.lastIndexOf(val)) {
            console.log('重复项的下标:' + index);
            console.log('重复项的值:' + val);
            this.repetitionIndex.push(index);
            message({
              type: 'warning',
              message: '一个项目内产品名称不可重复,请进行修改!',
              showClose: true,
            });
            sum += 1;
          }
        });
        // console.log(' this.repetitionIndex :>> ', this.repetitionIndex);
        //判断名字为空
        arrName.forEach((item) => {
          if (item == '') {
            sum += 1;
            this.Once_sure = true;
          }
        });

        // console.log('sum :>> ', sum);
      }
      //如果判断sum=0;无错误,保存即可
      if (sum == 0) {
        this.isDisable = true;
        let res = await HttpClient.post('program/program_save1', {
          updates: {
            project_name: this.Program.program.project_name,
            category: this.Program.program.category,
            status: '已保存',
            sub: this.$store.state.userid,
            panel_name: this.xlsx_name,
            _id: this.Program.program._id,
          },
          productDataList: this.Program.products,
        });
        console.log('res :>> ', res);
        if (res.status == 1) {
          message({
            type: 'warning',
            message: this.$t(`message.Message.ProjectNo`),
            showClose: true,
          });
        } else {
          // this.goBack();
          message({
            type: 'success',
            message: this.$t(`message.Message.Saved`),
            showClose: true,
          });
        }
      }

      this.$store.commit('$_setChangeRoute', true);
    },
    //修改产品数据
    amendMethod() {
      if (this.isDisable == false) {
        message({
          type: 'warning',
          message: '目前已在编辑状态!',
          showClose: true,
        });
      } else {
        this.isDisable = false;
        //取消前的值.
        (this.resourceProject_name = JSON.parse(JSON.stringify(this.Program.program.project_name))), //存储每次修改前最初的数据
          (this.resourceCategory = JSON.parse(JSON.stringify(this.Program.program.category))), //存储每次修改前最初的数据
          (this.resourceProduct = JSON.parse(JSON.stringify(this.Program.products))); //存储每次修改前最初的数据.
      }
    },
    //鼠标失焦,保留两位小数
    setTowNum(i, j) {
      console.log('this.import[i][j] :>> ', this.Program.products[i][j]);
      if (j > 10) {
        //  this.Program.products[i][j].toFixed(1)
        this.$set(this.Program.products[i], j, Number(this.Program.products[i][j]).toFixed(1));
        console.log('this.Program.products[i][j] :>> ', Number(this.Program.products[i][j]).toFixed(1));
      }
    },
    //保存
    // async _complete() {
    //   // if (this.isDisable == false) {
    //   //   // message({
    //   //   //   type: 'warning',
    //   //   //   message: '请先保存产品,再保存项目!',
    //   //   //   showClose: true,
    //   //   // });
    //   // } else {
    //   let res = await HttpClient.post('program/program_save1', {
    //     updates: {
    //       project_name: this.Program.program.project_name,
    //       category: this.Program.program.category,
    //       status: '已保存',
    //       sub: this.$store.state.userid,
    //       panel_name: this.xlsx_name,
    //       _id: this.Program.program._id,
    //     },
    //     productDataList: this.Program.products,
    //   });
    //   console.log('res :>> ', res);
    //   if (res.status == 1) {
    //     message({
    //       type: 'warning',
    //       message: '项目名重复,请求该后在保存!',
    //       showClose: true,
    //     });
    //   } else {
    //     this.goBack();
    //     message({
    //       type: 'success',
    //       message: '保存成功!',
    //       showClose: true,
    //     });
    //     // }
    //     // this.$EventBus.$emit('send_success', 'Haveproject');
    //   }
    // },
    //产品取消操作
    CancleEdit() {
      // this.isDisable = !this.isDisable;
      this.Program.program.project_name = this.resourceProject_name;
      this.Program.products = this.resourceProduct;
      this.Program.program.category = this.resourceCategory;
      this.isDisable = true;
    },
  },
  created() {
    // 监听 需要修改的项目信息,接收之后处理展示
    this.$EventBus.$on('sendProgram', (data) => {
      console.log('object :>> ', '接收到了SendProgram');
      this.Program = data;
      //设置增加产品的模板
      for (let index = 0; index < this.Program.products[0].length; index++) {
        this.ProductNull.push('');
      }
      //导入产品前面的 check 值
      for (let index = 0; index < this.Program.products.length; index++) {
        this.CheckedArr.push(false);
      }
      //产品来源判断中英文显示
      if (this.$store.state.lang == 'en') {
        this.Program.products.forEach((item) => {
          if (item[4] == '市售产品') {
            item[4] = 'In-market Product';
          } else if (item[4] == '开发原型') {
            item[4] = 'Prototype';
          } else if (item[4] == '竞争品牌') {
            item[4] = 'Benchmark';
          }
          switch (item[5]) {
            case '膏霜':
              item[5] = 'Cream';
              break;
            case '乳液':
              item[5] = 'Lotion';
              break;
            case '啫喱':
              item[5] = 'Gel';
              break;
            case '油类':
              item[5] = 'Oil';
              break;
            case '其他':
              item[5] = 'Other';
              break;
            case '涂抹式面膜':
              item[5] = 'Smear mask';
              break;
            case '贴片式面膜':
              item[5] = 'Sheet mask';
              break;
            case '水类':
              item[5] = 'Toner';
              break;
          }
          switch (item[8]) {
            case '化学':
              item[8] = 'Chemical';
              break;
            case '物理':
              item[8] = 'Physical';
              break;
            case '物化结合':
              item[8] = 'Hybird';
              break;

            default:
              break;
          }
        });
      } else {
        this.Program.products.forEach((item) => {
          if (item[4] == 'In-market Product') {
            item[4] = '市售产品';
          } else if (item[4] == 'Prototype') {
            item[4] = '开发原型';
          } else if (item[4] == 'Benchmark') {
            item[4] = '竞争品牌';
          }
          switch (item[5]) {
            case 'Cream':
              item[5] = '膏霜';
              break;
            case 'Lotion':
              item[5] = '乳液';
              break;
            case 'Gel':
              item[5] = '啫喱';
              break;
            case 'Oil':
              item[5] = '油类';
              break;
            case 'Other':
              item[5] = '其他';
              break;
            case 'Smear mask':
              item[5] = '涂抹式面膜';
              break;
            case 'Sheet mask':
              item[5] = '贴片式面膜';
              break;
            case 'Toner':
              item[5] = '水类';
              break;
          }
          switch (item[8]) {
            case 'Chemical':
              item[8] = '化学';
              break;
            case 'Physical':
              item[8] = '物理';
              break;
            case 'Hybird':
              item[8] = '物化结合';
              break;

            default:
              break;
          }
        });
      }

      //取消前的值.
      (this.resourceProject_name = JSON.parse(JSON.stringify(this.Program.program.project_name))), //存储每次修改前最初的数据
        (this.resourceCategory = JSON.parse(JSON.stringify(this.Program.program.category))), //存储每次修改前最初的数据
        (this.resourceProduct = JSON.parse(JSON.stringify(this.Program.products))); //存储每次修改前最初的数据.
      this.Res_program = this.Program.program;

      this.title = this.Program.program.project_name;
      this.down_name = this.Program.program.panel_files;

      this.panel_val = this.Program.program.panel_name;
      this.before_id = this.Program.program._id;
    });
    this.$EventBus.$on('sendProgram_name', (data) => {
      this.import_name = data;
      console.log('我是从' + this.import_name + '来的');
    });
    this.$EventBus.$on('fileName', (data) => {
      console.log('fileName :>> ', data);
      this.fileNames = data;
    });
  },

  beforeDestroy() {
    // 取消监听
    this.$EventBus.$off('sendProgram');
    this.$EventBus.$off('sendProgram_name');
    this.$store.commit('$_setChangeRoute', true);
  },
  mounted() {
    this.$store.commit('$_setChangeRoute', false);
    this.req_category();
    this.max_border = 1;
    this.getOptions();

    setTimeout(() => {
      this.loading = false;
    }, 2000);
    // console.log(this.Res_program);
    // if (this.Program.program.panel_name != "") {
    //   let tou = this.Program.program.panel_name.slice(0, 7)
    //   let wei = this.Program.program.panel_name.slice(-4)
    //   console.log(tou);
    //   console.log(wei);

    //   this.pro_name = tou + "..." + wei;
    //   console.log(this.pro_name);
    // }
  },
};
</script>

<style>
div.tooltipStyless {
  width: 130px;
}
</style>

<style lang="less" scoped>
@import url('../../style/Project/ChangeProject.less');
</style>
