<template>
  <div id="Check">
    <div class="bt">
      <!-- 标题 -->
      <div class="headline">
        <!-- <span class="head_one">已有项目/</span><span class="head_two">项目信息修改</span> -->
        <el-page-header @back="goBack"><span slot="title">{{$store.state.lang == 'cn' ? '返回' : 'Back'}}</span> </el-page-header>
        <div class="projectName">
          {{ Program.program.project_name }}
        </div>
      </div>

      <div class="download" @click="downFile">
        <span> {{ $t(`message.Project.check.Download`) }} </span>
      </div>
    </div>

    <!-- 项目内容 -->
    <div class="product-detail">
      <div class="content_box" ref="scrollRef">
        <!-- 项目标题 -->
        <div class="product-header" v-show="$store.state.lang == 'cn'">
          <!-- <div class="allCheck">
            <el-checkbox v-model="allChecked"></el-checkbox>
          </div> -->
          <div
            class="pd_Name"
            v-for="(item, index) in ProductName"
            :key="index"
            :class="[index == 0 || index == 4 ? 'CancelFlex' : '', index == 0 ? 'Title' : '', index > 11 ? 'changeColor' : '']">
            <span class="MustReat" v-show="index == 0 || index == 4">*</span>
            <div class="itemName">{{ item }}</div>
          </div>
        </div>
        <!-- 项目标题 -->
        <div class="product-header" v-show="$store.state.lang == 'en'">
          <div
            class="pd_Name"
            v-for="(item, index) in enProductName"
            :key="index"
            :class="[index == 0 || index == 4 ? 'CancelFlex' : '', index == 0 ? 'Title' : '', index > 11 ? 'changeColor' : '']">
            <span class="MustReat" v-show="index == 0 || index == 4">*</span>
            <div class="itemName">{{ item }}</div>
          </div>
        </div>
        <!-- 项目信息 -->
        <div class="product-box">
          <div
            class="product-message"
            v-for="(item, index) in Program.products"
            :key="index"
            :class="[index == Program.products.length - 1 ? 'endBorder' : '']">
            <!-- <el-checkbox v-model="CheckedArr[index]"></el-checkbox> -->
            <div class="ProductData" v-for="(ite, inde) in item" :key="inde" :class="[inde == 0 ? 'TitelId' : '']">
              <div class="item" :class="[isDisable ? 'CancelBorder' : '']">
                <input
                  v-if="inde != 4 && inde != 5 && inde != 9"
                  type="text"
                  v-model="item[inde]"
                  :disabled="isDisable"
                  @blur="setTowNum(index, inde)" />

                <el-form ref="form">
                  <el-form-item v-if="inde == 4">
                    <el-select v-model="item[4]" placeholder="请选择产品来源" :disabled="isDisable">
                      <el-option label="开发原型" value="开发原型"></el-option>
                      <el-option label="市售产品" value="市售产品"></el-option>
                      <el-option label="竞争品牌" value="竞争品牌"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item v-if="inde == 5">
                    <el-select v-model="item[5]" placeholder="请选产品性状" :disabled="isDisable">
                      <el-option label="乳液" value="乳液"></el-option>
                      <el-option label="啫喱" value="啫喱"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item v-if="inde == 9">
                    <el-select v-model="item[9]" placeholder="请选产品颜色" :disabled="isDisable">
                      <el-option label="白色" value="白色"></el-option>
                      <el-option label="黑色" value="黑色"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>

        <!-- 当前页面的空状态 -->
        <div class="null_page" v-show="0">
          <div class="null_img">
            <img src="https://newstore.vynior.com/Group%2033553%402x.png" alt="" />
          </div>
          <div class="null_message">暂无新建产信息哦</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Check',
  data() {
    return {
      Index: 0,
      Program: [],
      ProductName: [
        '产品名称',
        '品牌',
        '生产日期',
        '生产批号',
        '产品来源',
        '产品性状',
        'SPF值',
        'PA指数',
        '物理化学防晒',
        '产品颜色',
        '上市国家',
        '备注',
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度(2圈)',
        '增白程度(5圈)',
        '涂抹难易程度',
        '泛白程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '增白程度(即时)',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤水感(即时)',
        '皮肤油感(即时)',
        '增白程度(2分钟)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤水感(2分钟)',
        '皮肤油感(2分钟)',
        '增白程度(5分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '皮肤水感(5分钟)',
        '皮肤油感(5分钟)',
        '总体吸收速度',
      ], //产品维度
      enProductName: [
        'Product Name',
        'Brand',
        'Date of Production',
        'Batch No.',
        'Source',
        'Phase',
        'SPF value',
        'PA rating',
        'Formulation System',
        'Color',
        'Country for listing',
        'Note',
        ' Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability(2rubs)',
        'Whitening(5rubs)',
        'Spreadability(10rubs)',
        'Chalky',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Whitening(instant)',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Watery(instant)',
        'Oiliness(instant)',
        'Whitening(2min)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Watery(2min)',
        'Oiliness(2min)',
        'Whitening(5min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Watery(5min)',
        'Oiliness(5min)',
        'Speed of absorption',
      ],
      allChecked: false,
      CheckedArr: [],
      isDisable: true,
    };
  },
  methods: {
    //返回
    goBack() {
      if (this.$store.state.backFrom == 1) {
        //返回到我的项目首页

        this.$EventBus.$emit('back_project', 'myproject');
      } else if (this.$store.state.backFrom == 2) {
        //返回到我的项目
        this.$EventBus.$emit('changeHaveproject', 'Haveproject');
        console.log('执行了goback :>> ');
      }
    },
    //下载模板1
    downFile() {
     if(this.$store.state.lang =="cn"){
         let a = document.createElement('a');
      a.href = this.Program.program.panel_files; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
     }else if(this.$store.state.lang =="en"){
            let a = document.createElement('a');
      a.href = this.Program.program.panel_files_en; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
     }
    },
  },
  watch:{
    "$store.state.lang":{
      handler(){
        console.log("检测到改变了");
        if(this.$store.state.lang == "en"){
           this.Program.products.forEach((item,index)=>{
              console.log(this.Program.products[index],111,this.Program.products[index][4]);
              if(this.Program.products[index][4]== "开发原型"){
                  this.$set(this.Program.products[index],4,"Prototype")
              }else if(this.Program.products[index][4]== "竞争品牌"){
                   this.$set(this.Program.products[index],4,"Benchmark")
              }else if(this.Program.products[index][4]== "市售产品"){
                   this.$set(this.Program.products[index],4,"In-market Product")
              }else if(this.Program.products[index][4]== "无"){
                   this.$set(this.Program.products[index],4,"None")
              }
              // ! 产品性状 修改
               if(this.Program.products[index][5]== "膏霜"){
                  this.$set(this.Program.products[index],5,"Cream")
              }else if(this.Program.products[index][5]== "乳液"){
                   this.$set(this.Program.products[index],5,"Lotion")
              }else if(this.Program.products[index][5]== "啫喱"){
                   this.$set(this.Program.products[index],5,"Gel")
              }else if(this.Program.products[index][5]== "油类"){
                   this.$set(this.Program.products[index],5,"Oil")
              }else if(this.Program.products[index][5]== "其他"){
                   this.$set(this.Program.products[index],5,"Other")
              }else if(this.Program.products[index][5]== "涂抹式面膜"){
                   this.$set(this.Program.products[index],5,"Smear mask")
              }else if(this.Program.products[index][5]== "贴片式面膜"){
                   this.$set(this.Program.products[index],5,"Sheet mask")
              }else if(this.Program.products[index][5]== "水类"){
                   this.$set(this.Program.products[index],5,"Toner")
              }else if(this.Program.products[index][5]== "无"){
                   this.$set(this.Program.products[index],5,"None")
              }
              // ! 物理化学防晒
               if(this.Program.products[index][8]== "化学"){
                 this.$set(this.Program.products[index],8,"Chemical")
              }else if(this.Program.products[index][8]== "物理"){
                   this.$set(this.Program.products[index],8,"Physical")
              }else if(this.Program.products[index][8]== "物化结合"){
                   this.$set(this.Program.products[index],8,"Hybird")
              }else if(this.Program.products[index][8]== "暂无"){
                   this.$set(this.Program.products[index],8,"None")
              }
        })
        }else{
          this.Program.products.forEach((item,index)=>{
              console.log(this.Program.products[index],111,this.Program.products[index][4]);
              if(this.Program.products[index][4]== "Prototype"){
                  this.$set(this.Program.products[index],4,"开发原型")
              }else if(this.Program.products[index][4]== "Benchmark"){
                   this.$set(this.Program.products[index],4,"竞争品牌")
              }else if(this.Program.products[index][4]== "In-market Product"){
                   this.$set(this.Program.products[index],4,"市售产品")
              }else if(this.Program.products[index][4]== "None"){
                   this.$set(this.Program.products[index],4,"无")
              }
             // ! 产品性状 修改
                if(this.Program.products[index][5]== "Cream"){
                  this.$set(this.Program.products[index],5,"膏霜")
              }else if(this.Program.products[index][5]== "Lotion"){
                   this.$set(this.Program.products[index],5,"乳液")
              }else if(this.Program.products[index][5]== "Gel"){
                   this.$set(this.Program.products[index],5,"啫喱")
              }else if(this.Program.products[index][5]== "Oil"){
                   this.$set(this.Program.products[index],5,"油类")
              }else if(this.Program.products[index][5]== "Other"){
                   this.$set(this.Program.products[index],5,"其他")
              }else if(this.Program.products[index][5]== "Smear mask"){
                   this.$set(this.Program.products[index],5,"涂抹式面膜")
              }else if(this.Program.products[index][5]== "Sheet mask"){
                   this.$set(this.Program.products[index],5,"贴片式面膜")
              }else if(this.Program.products[index][5]== "Toner"){
                   this.$set(this.Program.products[index],5,"水类")
              }else if(this.Program.products[index][5]== "None"){
                   this.$set(this.Program.products[index],5,"无")
              }
              // ! 物理化学防晒
              if(this.Program.products[index][8]== "Chemical"){
                 this.$set(this.Program.products[index],8,"化学")
              }else if(this.Program.products[index][8]== "Physical"){
                   this.$set(this.Program.products[index],8,"物理")
              }else if(this.Program.products[index][8]== "Hybird"){
                   this.$set(this.Program.products[index],8,"物化结合")
              }else if(this.Program.products[index][8]== "None"){
                   this.$set(this.Program.products[index],8,"暂无")
              }
        });
      
        }
      }
    }
},
  created() {
    console.log('进入到check :>> ');

    if (this.$store.state.showProgram != '') {
      console.log('data :>> ', this.$store.state.showProgram);
      this.Program = this.$store.state.showProgram;

      console.log('this.Program :>> ', this.Program);
    }
  },
  mounted() {},
  beforeDestroy() {
    console.log('CheckbeforeDestroy :>> ');
  },
  destroyed() {
    console.log('Checkbdestroyed :>> ');
    this.$store.commit('$_addShowProgram', '');
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Project/Check.less');
</style>
