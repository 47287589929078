<template>
  <!-- 我的项目首页 -->
  <div id="MyProject">
    <!-- toubu -->
    <Header></Header>

    <!-- 导航栏 -->
    <div class="nav" :class="$store.state.lang == 'en' ? 'english' : ''">
      <el-row class="tac">
        <el-col :span="12">
          <el-menu :default-active="click_list" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
            <el-submenu index="1" @click="comName = 'MyPcontent'">
              <template slot="title">
                <i class="el-icon-s-home"></i>
                <span @click="ChangeTemplate('MyPcontent')" class="myproject">{{ $t(`message.Project.one`) }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="1-1" @click="GoDraft"> <i class="el-icon-folder-add"></i>{{ $t(`message.Project.two`) }}</el-menu-item>
                <el-menu-item index="1-2" @click="ChangeTemplate('HaveProject')">
                  <i class="el-icon-folder"></i>{{ $t(`message.Project.three`)
                  }}<span class="have_num" :class="comName == 'HaveProject' ? 'add_num_color' : ''">{{ project_num }}</span></el-menu-item
                >
                <el-menu-item index="1-3" @click="ChangeTemplate('MyDrafts')">
                  <i class="el-icon-takeaway-box"></i>{{ $t(`message.Project.four`)
                  }}<span class="drafts_num" :class="comName == 'MyDrafts' ? 'add_num_color' : ''">{{ drafts_num }}</span></el-menu-item
                >
                <el-menu-item index="1-4" style="display: none">隐藏设置,1-4默认展开选项</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </div>

    <!-- content -->
    <div class="message-element">
      <component :is="comName"></component>
    </div>

    <!-- 未保存,跳转路由提示ui-->
    <div class="notarize_save" v-show="leaveRoute.Show">
      <!-- 遮罩层 -->
      <!-- 提示框 -->
      <div class="notarize_message">
        <!-- 内容信息 -->
        <div class="content_text">
          <div class="line_1" :class="$store.state.lang == 'en' ? 'addMargan' : ''">
            {{ $t(`message.Project.new.router`) }}

            <img src="https://newstore.vynior.com/xxx.png" alt="" @click="clearFocus" />
          </div>
          <div class="line_2">
            <img src="https://newstore.vynior.com/gth.png" alt="" />
            {{ $t(`message.Project.new.routerContent`) }}
          </div>
          <div class="line_3">
            <span class="cancle" @click="clearFocus"> {{ $t(`message.Project.Cancel`) }}</span>
            <span class="sure" @click="GoNext"> {{ $t(`message.Project.OK`) }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header';
import MyPcontent from './MyPcontent.vue';
import CreateProject from './CreateProject.vue';
import HaveProject from './HaveProject.vue';
import MyDrafts from './MyDrafts.vue';
import Check from './Check.vue';
export default {
  name: 'MyProject',
  data() {
    return {
      leaveRoute: {
        Show: false,
        nextPath: '',
        nextTemplate: '',
      },

      click_list: '1-4', //高亮导航栏
      project_num: 0,
      drafts_num: 0,
      arr: [
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '11',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '23',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
        {
          open: '08/18 11:02',
          id: 'HT2022A001',
          createTime: '2022/08/12',
          num: '35',
          goodsName: '雅诗兰黛小棕瓶、兰蔻菁纯精华、莱珀妮鱼子精华琼贵紧颜液、兰蔻小黑瓶...',
          cz1: '项目信息修改',
          cz2: '分析',
          cz3: '导出',
          cz4: '删除',
        },
      ],
      comName: 'MyPcontent',
    };
  },
  components: {
    Header,
    CreateProject,
    MyPcontent,
    HaveProject,
    MyDrafts,
    Check,
  },
  watch: {
    comName: {
      handler(newVal, oldVar) {
        if (newVal != oldVar) {
          console.log(newVal);
          if (newVal == 'CreateProject') {
            this.click_list = '1-1';
          } else if (newVal == 'HaveProject') {
            this.click_list = '1-2';
          } else if (newVal == 'MyDrafts') {
            this.click_list = '1-3';
          } else if (newVal == 'MyPcontent') {
            this.click_list = '1-4';
          }
        }
      },
    },
  },
  created() {
    // 监听 中央事件总线 获取Haveproject传过来的数据
    this.$EventBus.$on('sendHavaProject_Num', (data) => {
      this.project_num = data;
      console.log(this.project_num);
      console.log('project_num');
    });
    // 监听草稿箱
    this.$EventBus.$on('sendHavaDrafts_Num', (data) => {
      this.drafts_num = data;
      console.log(this.drafts_num);
      console.log('drafts_num');
    });
    // 监听修改页面的完成按钮（待修改）
    this.$EventBus.$on('pageChange', (data) => {
      this.comName = data;
      console.log(this.comName);
    });

    //接收点击修改项目
    this.$EventBus.$on('send_changepage', (data) => {
      console.log(data);
      if (data == 'Haveproject') {
        this.click_list = '1-2';
      }
    });
    //接收点击查看项目
    this.$EventBus.$on('send_checkpage', (data) => {
      console.log(data);
      if (data == 'Check') {
        this.click_list = '1-2';
      }
    });
    //接收我的项目返回项目
    this.$EventBus.$on('change_click', (data) => {
      console.log(data);
      if (data == 'yes') {
        this.click_list = '1-4';
      }
    });

    //新建项目点击完成
    this.$EventBus.$on('send_success', (data) => {
      console.log(data);
      if (data == 'Haveproject') {
        this.click_list = '1-2';
        this.comName = 'HaveProject';
      }
    });

    //接收点击新建项目返回
    this.$EventBus.$on('back_project', (data) => {
      console.log(data);
      if (data == 'myproject') {
        console.log('返回我的项目');
        this.comName = 'MyPcontent';
      }
    });

    //接收点击新建项目返回
    this.$EventBus.$on('changeCheck', (data) => {
      console.log(data);
      if (data == 'Check') {
        console.log('进入检查页面');
        this.comName = 'Check';
      }
    });
    //接收点击新建项目返回
    this.$EventBus.$on('changeHaveproject', (data) => {
      console.log(data);
      if (data == 'Haveproject') {
        console.log('去我的项目');
        this.comName = 'HaveProject';
      }
    });
  },
  methods: {
    // 更改组件
    ChangeTemplate(val) {
      this.leaveRoute.nextTemplate = val;
      if (!this.$store.state.changeRoute) {
        this.leaveRoute.Show = true;
      } else {
        this.comName = val;
      }
    },
    // 取消强制跳转
    clearFocus() {
      this.leaveRoute.Show = false;
      this.$EventBus.$emit('focusValue', [0, 1, 0, 0, 0]);
    },
    // 强制跳转路由;
    GoNext() {
      console.log('强制跳转');
      this.$store.commit('$_setChangeRoute', true);
      if (this.leaveRoute.nextPath != '') {
        this.$router.push({ path: this.leaveRoute.nextPath });
      } else {
        this.comName = this.leaveRoute.nextTemplate;
        this.leaveRoute.Show = false;
      }
    },
    GoDraft() {
      if (!this.$store.state.changeRoute) {
        this.leaveRoute.Show = true;
      } else {
        this.comName = 'CreateProject';
        // this.leaveRoute.nextTemplate = 'CreateProject';
        this.$store.commit('$_change_upload', true);
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  beforeDestroy() {
    // 结束监听 中央事件总线
    this.$EventBus.$off('send_success');
    this.$EventBus.$off('sendHavaProject_Num');
    this.$EventBus.$off('sendHavaDrafts_Num');
    this.$EventBus.$off('send_changepage');
    this.$EventBus.$off('back_project');
    this.$EventBus.$off('changeCheck');
    // this.$EventBus.$off("pageChange");
  },
  beforeRouteLeave(to, from, next) {
    console.log(this.$store.state.changeRoute);
    if (!this.$store.state.changeRoute) {
      this.leaveRoute.Show = true;
    } else {
      next();
    }
    this.leaveRoute.nextPath = to.name;
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Project/MyProject.less');
</style>
