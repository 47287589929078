<template>
  <!-- 我的项目---已有项目组件 -->
  <div id="HaveProject">
    <div
      class="content"
      v-if="diss == 0"
      :class="$store.state.lang == 'en' ? 'english' : ''"
    >
      <!-- 头部说明按钮 -->
      <div class="title-bar">
        <span class="havepj">{{ $t(`message.Project.HaveTitle`) }}</span>
        <!-- 项目编号 -->
        <div class="pjID">
          <span>{{ $t(`message.Project.Item`) }}</span>
          <input
            type="text"
            v-model="product_name"
            class="pjid"
            @keyup.enter="Search"
          />
        </div>
        <!-- 创建时间 -->
        <div class="createTime">
          <span>{{ $t(`message.Project.CreationTimes`) }}</span>
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              :range-separator="$t(`message.Project.To`)"
              :start-placeholder="$t(`message.Project.start`)"
              :end-placeholder="$t(`message.Project.end`)"
              value-format="yyyy/MM/dd"
              @change="pickerChangeFn"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 产品信息 -->
        <div class="message">
          <span>{{ $t(`message.Project.information`) }}</span>
          <input
            type="text"
            v-model="product_message"
            class="value"
            @keyup.enter="Search"
          />
        </div>
        <!--项目按钮 button -->
        <div
          class="button"
          v-show="$store.state.lang == 'cn'"
        >
          <img
            src="https://newstore.vynior.com/hthj5.png"
            @click="Search"
          />
          <img
            src="https://newstore.vynior.com/hthj6.png"
            @click="Reset"
            class="rest"
          />
          <div class="nums_show">
            <img
              @click="show_choose"
              src="https://newstore.vynior.com/hthj7.png"
              alt=""
            />
            <div class="choose_box">
              <div class="choose_two">
                <div
                  class="choose1"
                  @click="allderive"
                >{{ $t(`message.Project.Export`) }}</div>
                <div
                  class="choose2"
                  @click="notarize_delete"
                >{{ $t(`message.Project.Delete`) }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- english -->
        <div
          class="button"
          v-show="$store.state.lang == 'en'"
        >
          <img
            src="https://newstore.vynior.com/ENsearch.png"
            @click="Search"
          />
          <img
            src="https://newstore.vynior.com/ENreset.png"
            @click="Reset"
            class="rest"
          />
          <div class="nums_show">
            <img
              @click="show_choose"
              src="https://newstore.vynior.com/Enbybatch.png"
              alt=""
            />
            <div class="choose_box">
              <div class="choose_two">
                <div
                  class="choose1"
                  @click="allderive"
                >Export</div>
                <div
                  class="choose2"
                  @click="notarize_delete"
                >Delete</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 数据盒子 -->
      <div class="content_box">
        <!-- 内容标题 -->
        <div class="content-Title">
          <div class="check">
            <input
              type="checkbox"
              @click="checkAll"
              :checked="allin"
            />
          </div>

          <span class="item">{{ $t(`message.Project.item`) }}</span>
          <div class="checkbox-img">
            <span>{{ $t(`message.Project.creationTimes`) }}</span>
            <img
              src="https://newstore.vynior.com/hthj8.png"
              class="by_order"
              @click="sort_list"
            />
          </div>
          <span calss="category">{{ $t(`message.Project.category`) }}</span>
          <span class="number">{{ $t(`message.Project.quantity`) }}</span>
          <span class="name">{{ $t(`message.Project.productName`) }}</span>
          <span class="operation">{{ $t(`message.Project.operation`) }}</span>
        </div>

        <!-- 内容 -->
        <div
          class="data_box"
          v-show="empty_ds == 1"
        >
          <div
            class="content-message"
            :class="[index === arr.length - 1 ? 'displayLine' : '']"
            v-for="(item, index) in Res_Start_data.result"
            :key="index"
          >
            <!-- checked -->
            <div class="content_boxs">
              <div class="checked">
                <input
                  type="checkbox"
                  :checked="allOne[index]"
                  @click="checkOne(index)"
                />
              </div>
              <!-- name -->
              <div class="message-id">
                <div style="width: auto; max-width: 159px">
                  <el-tooltip
                    :open-delay="500"
                    class="item"
                    effect="dark"
                    :content="item.program_name"
                    placement="top"
                    popper-class="back_ground"
                  >
                    <span>{{ item.program_name }}</span>
                  </el-tooltip>
                </div>
              </div>
              <!-- time -->
              <div class="message-time">
                <span class="message-time">{{ item.create_time }}</span>
              </div>
              <!-- category -->
              <div class="message-classify">
                <span>{{ item.category }}</span>
              </div>
              <!-- number -->
              <div class="message-num">
                <span>{{ item.productsnumber }}</span>
              </div>
              <!-- productName -->
              <div class="message-name">
                <div style="width: auto; max-width: 540px">
                  <el-tooltip
                    :open-delay="500"
                    class="item"
                    effect="dark"
                    :content="item.productsnamelist.toString()"
                    placement="top"
                    popper-class="back_ground_max"
                  >
                    <span style="
                        max-width: 541px;
                        display: inline-block;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      ">{{ item.productsnamelist.toString() }}</span>
                  </el-tooltip>
                </div>
              </div>
              <span
                class="message-cz0"
                @click="goCheck(index)"
              >{{ (item.cz0 = $t(`message.Project.view`)) }}</span>
              <span
                class="message-cz1"
                @click="opens(index)"
              >{{ (item.cz1 = $t(`message.Project.edit`)) }}</span>
              <!-- <span class="message-cz5" @click="renew(index)" v-show="0">{{ (item.cz5 = $t(`message.Project.`)) }}</span> -->
              <span
                class="message-cz2"
                @click="toItem(index)"
              >{{ (item.cz2 = $t(`message.Project.forecast`)) }}</span>
              <span
                class="message-cz3"
                @click="singleDerive(index,item)"
              >{{ (item.cz3 = $t(`message.Project.derive`)) }}</span>
              <span
                class="message-cz4"
                @click="check_delte(index)"
              >{{ (item.cz4 = $t(`message.Project.delete`)) }}</span>
            </div>
          </div>
        </div>
        <!-- 搜索为空 -->
        <div
          class="empty_data"
          v-show="empty_d"
        >
          <div class="img">
            <img
              src="https://newstore.vynior.com/kong.png"
              alt=""
            />
          </div>
          <div class="message">{{ $t(`message.Project.NoResults`) }}</div>
          <div
            class="message2"
            v-show="$store.state.lang == 'cn'"
          >没有搜索到相关内容</div>
        </div>
        <!-- 账号下面空项目 -->
        <div
          class="empty_datas"
          v-show="empty_ds == 0"
        >
          <div class="img">
            <img
              src="https://newstore.vynior.com/kong_data.png"
              alt=""
            />
          </div>
          <div class="message">{{ $t(`message.Project.NoProjects`) }}</div>
        </div>
      </div>
      <div
        class="page_footer"
        :class="empty_d == 1 ? 'add_margin' : ''"
      >
        <!-- 项目总和 -->
        <div
          class="projectNum"
          v-show="$store.state.lang == 'cn'"
        >
          <span v-show="searchStatus == true">已选择 <span class="choiceNum">{{ remember_choose.length }}</span>&nbsp;个项目&nbsp;|&nbsp;共&nbsp;&nbsp;{{ choiceNum }} &nbsp;个项目</span>
          <span v-show="searchStatus == false">已选择 <span class="choiceNum">{{ remember_choose.length }}</span>&nbsp;个项目&nbsp;|&nbsp;搜索到&nbsp;&nbsp;{{ choiceNum }} &nbsp;个项目</span>
        </div>
        <!-- english------------------------ -->
        <div
          class="projectNum"
          v-show="$store.state.lang == 'en'"
        >
          <span v-show="searchStatus == true">
            <span class="choiceNum">{{ remember_choose.length }} </span>&nbsp;projects selected&nbsp;|&nbsp;&nbsp;&nbsp;{{ choiceNum }} &nbsp;in
            total</span>
          <span v-show="searchStatus == false">
            <span class="choiceNum">{{ remember_choose.length }}</span>&nbsp;projects selected &nbsp;|&nbsp;&nbsp;{{ choiceNum }}&nbsp;projects found &nbsp;</span>
        </div>

        <!-- 页码展示 -->
        <template>
          <div
            class="blocks"
            v-show="choiceNum != 0"
          >
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="sum"
              background
            >
            </el-pagination>
          </div>
        </template>
      </div>

      <!-- 确认批量删除操作 -->
      <div
        class="notarize_delete"
        v-show="Once_sure"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div
              class="line_1"
              v-show="$store.state.lang == 'cn'"
            >
              确定批量删除&nbsp;<span class="all_num">{{ remember_choose.length }}</span>
              条产品信息吗?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sure = 0"
              />
            </div>
            <div
              class="line_1"
              v-show="$store.state.lang == 'en'"
            >
              Are you sure you want to delete&nbsp;<span class="all_num">{{ remember_choose.length }}</span> product?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sure = 0"
              />
            </div>
            <div class="line_2">
              <img
                src="https://newstore.vynior.com/gth.png"
                alt=""
              />
              {{ $t(`message.Project.ActionUndone`) }}
            </div>
            <div class="line_3">
              <span
                class="cancle"
                @click="Once_sure = 0"
              >{{ $t(`message.Project.Cancel`) }}</span>
              <span
                class="sure"
                @click="deletes_end"
              >{{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 删除具体项目操作 -->
      <div
        class="notarize_deletes"
        v-show="Once_sureProject"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div
              class="line_1"
              :class="Once_sureProject == 1 ? 'changeColor' : ''"
              v-show="$store.state.lang == 'cn'"
            >
              确定删除&nbsp;<span>{{ delete_pj_name }}</span> 项目吗?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sureProject = 0"
              />
            </div>
            <div
              class="line_1"
              :class="Once_sureProject == 1 ? 'changeColor' : ''"
              v-show="$store.state.lang == 'en'"
            >
              Are you sure you want to delete&nbsp;<span> {{ delete_pj_name }}</span> ?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sureProject = 0"
              />
            </div>
            <div class="line_2">
              <img
                src="https://newstore.vynior.com/gth.png"
                alt=""
              />
              {{ $t(`message.Message.DeletsHint`) }}
            </div>
            <div class="line_3">
              <span
                class="cancle"
                @click="Once_sureProject = 0"
              >{{ $t(`message.Project.Cancel`) }}</span>
              <span
                class="sure"
                @click="delete_one"
              >{{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 导入前的提醒事项ui -->
      <div
        class="replace_ui"
        v-show="Once_sureReplace"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              是否选择该项目重新上传?
              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sureReplace = 0"
              />
            </div>
            <div class="line_2">
              <img
                src="https://newstore.vynior.com/gth.png"
                alt=""
              />
              重新上传后,该项目当前数据将被上传覆盖
            </div>
            <div class="line_3">
              <span
                class="cancle"
                @click="Once_sureReplace = 0"
              >取消</span>
              <span
                class="sure"
                @click="change_show"
              >确定</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 新建项目的导入提示框 -->
      <!--导入数据提示ui -->
      <div
        class="import_tip"
        v-show="upload_success"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              新建项目
              <img
                src="https://newstore.vynior.com/cancelss.png"
                alt=""
                @click="upload_success = false"
              />
            </div>
            <div class="line_2">
              <!-- 上传文件 -->
              <div class="import_box">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreviews"
                  :on-remove="handleRemoves"
                  :before-remove="beforeRemoves"
                  :on-exceed="handleExceeds"
                  :before-upload="beforeUpload"
                  :file-list="fileLists"
                  multiple
                  :http-request="uploadFiles"
                  :show-file-list="false"
                >
                  <img
                    src="https://newstore.vynior.com/uploads.png"
                    alt=""
                    class="logo"
                  />
                  <div class="el-upload__text">将xlsx文件拖到此处，或点击上传文件</div>
                  <img
                    src="https://newstore.vynior.com/scmm.png"
                    alt=""
                    class="button"
                  />
                </el-upload>
              </div>
              <!-- 下载模板 -->
              <div class="download_box">
                <img
                  src="https://newstore.vynior.com/dlmm.png"
                  class="logo"
                />
                <span>如果您是首次使用，请先下载FAST模板</span>
                <img
                  src="https://newstore.vynior.com/xzmm.png"
                  class="down_load"
                  @click="downFile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChangeProject
      v-if="diss == 1"
      @change="changeV"
      @close="close"
    />
    <!-- <ChangeProject v-if="diss == 2" @change="changeV" @close="close" /> -->
    <router-view />
    <!-- 导出弹窗 -->
    <div
      class="export"
      v-show="showExport"
    >
      <!-- 提示框 -->
      <div class="export_box">
        <!-- 内容信息 -->
        <div class="content_text">
          <div class="line_1">
            <div
              class="export_box_text"
              v-if="$store.state.lang =='cn'"
            > 您确定导出 <span class="export_project_text">{{ExportName}}</span> 项目数据吗？</div>
            <div
              class="export_box_text"
              v-if="$store.state.lang =='en'"
            >Are you sure to export <span class="export_project_text">{{ExportName}}</span> ？ </div>
            <div
              class="img"
              @click="showExport =false"
            > <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
              /></div>
          </div>
          <div
            class="line_2"
            v-if="$store.state.lang =='cn'"
          >
            <img
              src="https://newstore.vynior.com/gth.png"
              alt=""
            />
            该项目产品暂无预测结果，仅会导出上传数据
          </div>
          <div
            class="line_2"
            v-if="$store.state.lang =='en'"
          >
            <img
              src="https://newstore.vynior.com/gth.png"
              alt=""
            />
            There are no predictions for this project product,only raw data will be exported
          </div>
          <div class="line_3">
            <span
              class="cancle"
              @click="showExport =false"
            >{{ $t(`message.Project.Cancel`) }}</span>
            <span
              class="sure"
              @click="unsingleDerive"
            >{{ $t(`message.Project.OK`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HttpClient } from '@/utils/http';
import { message } from '@/utils/resetMessage';
import ChangeProject from '../../pages/Project/ChangeProject.vue';
import getFilename from '../../utils/getFilename';
export default {
  name: 'HaveProject',
  data() {
    return {
      searchStatus: true, //控制搜索的状态显示
      footName: '共',
      Once_sureReplace: 0,
      products_val: [],
      open_index: -1, //跳转查看的下表
      replace: [], //上传替换文件的数据
      old_product_id_list: [], //记录旧项目的产品id
      Res_ID: [], //点击重新上传的产品信息
      upload_success: false, //重新上传的文档
      fileLists: [], //导入文件
      remember_choose: [], //记住勾选的项目id
      sort_value: false, //排序展示"
      page_current: -1, //监听页码
      empty_ds: 1,
      empty_d: 0, //空信息
      change_page: 0,
      total_: 0, //项目的总数目
      sum: 0, //页码总数
      pages: 0, //页码
      delete_index: -1, //删除项目的下标
      product_message: '', //项目信息
      product_name: '', //项目编号的数据
      Res_Start_data: [], //接受开始的项目信息
      Res_program: [], //接收修改项目信息的返回内容
      cz_arr: {
        cz0: '查看',
        cz1: '编辑',
        cz2: '预测',
        cz3: '导出',
        cz4: '删除',
        cz5: '重新上传',
      },
      myProject: [], //接收项目信息
      //删除具体项目
      Once_sureProject: 0,
      delete_pj_name: '',
      // 项目名称
      project_name: '',
      // 控制再次确认的值。
      Once_sure: 0,
      // 控制操作choose隐藏
      show_choose_num: 0,
      value1: null, //选择时间
      value2: '',
      arr: [],
      // 项目详情
      Programs: [],

      comName: 'HaveProject',
      choiceNum: 0,
      projectNum: 30,
      // 页面当前页
      currentPage3: 1,
      //   多选
      allin: false,
      //  单选
      allOne: [],
      allCount: 0,
      dialogFormVisible: false, //编辑弹出框弹出隐藏
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      formLabelWidth: '120px',
      diss: 0, //页面默认为显示已有项目
      ChangeVlue: [], // 点击修改 获取数组
      // ! 导出弹窗
      showExport:false,
      ExportName:"",  //项目名字
      unProjectindex:"",
      unProjectitem:"",
      Exporturl:"",
    };
  },
  components: {
    ChangeProject,
  },
  watch: {
    //当前页
    page_current: {
      handler(newv, oldv) {
        console.log('当前页面', newv);
        console.log('旧的页面', oldv);
        if (newv != oldv) {
          this.allin = false;

          for (let index = 0; index < this.allOne.length; index++) {
            this.allOne[index] = false;
          }
        }
      },
    },
    // 监听当前页面的项目信息变化（跳转页面时）
    Res_Start_data: {
      handler(newr, oldr) {
        console.log(newr.result);
        if (newr != oldr) {
          //切换页面，恢复勾选多选框。
          console.log(this.remember_choose);
          // console.log(this.allOne);
          // console.log(this.Res_Start_data.result);
          //判断勾选的id 在当前页面是否存在，存在就勾选（true）
          for (let y = 0; y < this.remember_choose.length; y++) {
            for (let z = 0; z < newr.result.length; z++) {
              // this.remember_choose[y] == newr.result[z].programid
              if (this.remember_choose[y] == newr.result[z].programid) {
                console.log('勾选下标为', z);
                this.allOne[z] = true;
              }
            }
          }

          //同步全选按钮
          var num = 0;
          //判断当前页面勾选的数量
          for (let index = 0; index < this.allOne.length; index++) {
            if (this.allOne[index] == true) {
              num++;
            }
          }
          //用勾选的数量和当前页的数量作对比，=则是全选。
          if (newr.result.length == num && newr.result.length != 0) {
            // this.checkAll();
            this.allin = true;
          }
        }
      },
    },
    allin(newv, oldv) {
      console.log('oldv :>> ', oldv);
      console.log('newv :>> ', newv);
      console.log('this.allin :>> ', this.allin);
    },
    "$store.state.lang":{
       handler(){
        console.log("监听语言切换");
        if(this.$store.state.lang=="cn"&&this.Res_Start_data){
          this.Res_Start_data.result.forEach((item,index)=>{
              if(item.category == "Sun care"){
                  this.Res_Start_data.result[index].category = "防晒霜"
              }
          })
        }else{
          console.log("en");
          this.Res_Start_data.result.forEach((item,index)=>{
              if(item.category == "防晒霜"){
                  this.Res_Start_data.result[index].category = "Sun care"
              }
          })
        }
       },
      immediate:true,
    }
  },
  methods: {
    //点击重新上传
    renew(i) {
      this.Once_sureReplace = 1;
      this.open_index = i;
      this.Res_ID = this.Res_Start_data.result[i];
    },
    //切换显示隐藏
    change_show() {
      this.Once_sureReplace = 0;
      // this.upload_success = false;
      this.upload_success = true;
      // this.change_val(val);//显示重新上传的按钮.
      this.req_project();
    },

    //重新上传返回的原产品信息
    async req_project() {
      this.old_product_id_list = [];
      await HttpClient.post('program/findbyid', {
        id: this.Res_ID.programid,
      }).then((res) => {
        console.log('重新上传的项目返回来的信息', res.productlist);
        res.productlist.forEach((item) => {
          this.old_product_id_list.push(item._id);
        });
      });

      console.log(this.old_product_id_list);
    },

    //下载模板1
    downFile() {
      let a = document.createElement('a');
      a.href = 'https://www.ht-fast.com/FAST信息&属性数据导入模板【防晒霜CN】.xlsx'; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
    },
    // NOTE批量导出方法
    async allderive() {
      // console.log("this.allOne :>> ", this.allOne);
      if (this.allCount <= 1) {
        message({
          showClose: true,
          message: this.$t(`message.Message.export`),
          type: 'warning',
        });
      } else {
        let programlist = this.remember_choose;
        if (programlist.length == 0) {
          message({
            showClose: true,
            message: '请选择项目进行导出',
            type: 'warning',
            customClass: 'change_bar',
          });
          return;
        }
        let deriveVerify = await HttpClient.post('program/beforeDerive', {
          programlist,
        });
        // console.log("deriveVerify :>> ", deriveVerify);
        if (deriveVerify.status == 1) {
          message({
            showClose: true,
            message: deriveVerify.message,
            type: 'warning',
          });
          return;
        }
        let response = await HttpClient.post(
          'program/derive',
          {
            programlist,
             language:this.$store.state.lang
            
          },
          { responseType: 'arraybuffer' }
        );
        let name = getFilename(response);
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        let downloadElement = document.createElement('a');
        let allProjecthref = window.URL.createObjectURL(blob);
        downloadElement.href = allProjecthref;
        downloadElement.download = `${name}`;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(allProjecthref);
      }
    },
    // ! NOTE单个导出方法
    async singleDerive(index,item) {
      this.unProjectindex = index
      this.unProjectitem = item
      console.log(item.program_name,"item");
      let programlist = [this.Res_Start_data.result[index].programid];
      // console.log("programlist :>> ", programlist);
      let deriveVerify = await HttpClient.post('program/beforeDerive', {
        programlist,
      });
      // console.log("deriveVerify :>> ", deriveVerify);
      // !  判断完之后，如果是1就弹窗
      if (deriveVerify.status == 1) {
        // message.warning(deriveVerify.message);
       this.showExport =true
       this.ExportName = item.program_name
       console.log();
       return
      }
      // ! 如果不是1 就说明分析过可以导出项目
      let response = await HttpClient.post(
        'program/derive',
        {
          programlist,
           language:this.$store.state.lang
        },
        { responseType: 'arraybuffer' }
      );
      let name = getFilename(response);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      let downloadElement = document.createElement('a');
      let oneProjecthref = window.URL.createObjectURL(blob);
      downloadElement.href = oneProjecthref;
      downloadElement.download = `${name}`;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(oneProjecthref);
     
      // console.log("name :>> ", name);
      // console.log("response :>> ", response);
    },
    // ! 确定导出没有分析的项目
    async unsingleDerive() {
         await HttpClient.post('program/open1', {
        id: this.Res_Start_data.result[this.unProjectindex].programid,
      }).then((res)=>{
          this.Exporturl = this.$store.state.lang=="cn"? res.program.panel_files :res.program.panel_files_en  //下载的地址
      })
       let a = document.createElement('a');
     a.href = this.Exporturl // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
      this.showExport = false
    },
  
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_current = val;
      this.pages = val - 1;

      this.fist_req();
      // if (this.change_page) {
      //   this.fist_req();
      // } else {
      // this.Search();
      // }
    },
    // 全选
    checkAll() {
      //勾选
      if (this.allin == false) {
        console.log(this.Res_Start_data.result);
        console.log('全选,初始记住id数组', this.remember_choose);

        //如果记住id数组为空 就直存
        if (this.remember_choose.length == 0) {
          for (let index = 0; index < this.Res_Start_data.result.length; index++) {
            this.remember_choose.push(this.Res_Start_data.result[index].programid);
          }
        } else {
          //不为空就判断之后再存
          //点击之后 把所有的当前页的项目 id 存入到id_list
          for (let y = 0; y < this.Res_Start_data.result.length; y++) {
            if (this.remember_choose.indexOf(this.Res_Start_data.result[y].programid) == -1) {
              this.remember_choose.push(this.Res_Start_data.result[y].programid);
            }
          }

          console.log('全选后的的记住id是', this.remember_choose);
        }

        this.allCount = this.allOne.length;
        console.log('this.Res_Start_data :>> ', this.Res_Start_data);
        if (this.Res_Start_data.result.length != 0) this.allin = true;
        else this.allin = false;
        for (let index = 0; index < this.allOne.length; index++) {
          this.allOne[index] = true;
        }
      } else {
        //取消勾选
        this.allin = false;
        this.allCount = 0;
        //删除 全选的 remeber_id
        for (let x = 0; x < this.remember_choose.length; x++) {
          for (let index = 0; index < this.Res_Start_data.result.length; index++) {
            if (this.remember_choose[x] == this.Res_Start_data.result[index].programid) {
              this.remember_choose.splice(x, 1);
            }
          }
        }
        console.log('全选后的的删除的id是', this.remember_choose);
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.allOne[index] = false;
        }
      }
    },
    // 单选
    checkOne(val) {
      console.log(val);

      if (!this.allOne[val]) {
        this.allOne[val] = true;
        this.add_remember(val);
      } else {
        this.allOne[val] = false;
        this.delete_remember(val);
      }

      this.allCount = 0;
      for (let index = 0; index < this.Res_Start_data.result.length; index++) {
        if (this.allOne[index] == true) {
          this.allCount++;
        }
      }
      console.log(this.allCount);
      console.log(this.allOne);

      if (this.allCount == this.Res_Start_data.result.length) {
        this.checkAll();
      } else {
        this.allin = 0;
      }
    },

    //增加记住的id

    add_remember(val_) {
      this.remember_choose.push(this.Res_Start_data.result[val_].programid);
      console.log('增加过后的记住数组', this.remember_choose);
    },
    //删除记住的id
    delete_remember(val_) {
      console.log(this.Res_Start_data.result[val_].programid);
      for (let index = 0; index < this.remember_choose.length; index++) {
        if (this.Res_Start_data.result[val_].programid == this.remember_choose[index]) {
          this.remember_choose.splice(index, 1);
        }
      }
      console.log('删除之后的记住数组', this.remember_choose);
    },

    // 点击前往修改项目信息页面
    async opens(i) {
      console.log(11111, i);
      this.diss = 1;
      await HttpClient.post('program/open1', {
        id: this.Res_Start_data.result[i].programid,
      }).then((res) => {
        this.Res_program = res;
        console.log(this.Res_program);

        this.$EventBus.$emit('sendProgram', this.Res_program);
        this.$store.commit('$_change_backFrom', 2); //更改返回路径

        this.$EventBus.$emit('fileName', this.Res_program.program.panel_name);
      });
    },
    //点击查看前往check页面
    async goCheck(i) {
      await HttpClient.post('program/open1', {
        id: this.Res_Start_data.result[i].programid,
      }).then((res) => {
        this.$store.commit('$_addShowProgram', res);

        // console.log('Res_program :>> ', this.Res_program);
        this.$store.commit('$_change_backFrom', 2); //更改返回路径
        this.$EventBus.$emit('changeCheck', 'Check');
        // this.$EventBus.$emit('takeOut', this.Res_program);

        console.log(res.program.panel_files,"res222");
      });
    },
    close() {
      this.fist_req();
      this.diss = 0;
    },
    changeV(e) {
      console.log('e :>> ', e);
      console.log(e);
      this.diss = e;
    },
    //跳转单品分析页
    toItem(val) {
      this.$router.push({
        name: 'itemanalysis',
        params: {
          project_name: this.Res_Start_data.result[val].program_name,

          page_id: '/itemanalysis',
        },
      });
      // this.clickItem();
    },
    clickItem() {
      this.$EventBus.$emit('Toitem', 'toItem');
    },

    // 控制choose_num
    show_choose() {
      this.show_choose_num = !this.show_choose_num;
    },

    // 确认多选删除操作
    notarize_delete() {
      if (this.allCount <= 1) {
        message({
          type: 'warning',
          showClose: true,
          message: this.$t(`message.Message.delete`),
        });
      } else {
        this.Once_sure = 1;
      }
    },
    // 单选删除操作
    check_delte(index) {
      console.log(index);

      // 给删除操作赋值名称
      this.delete_index = index;
      this.delete_pj_name = this.Res_Start_data.result[index].program_name;
      this.Once_sureProject = 1;
    },
    // 搜索按钮
    Search() {
      console.log('搜索按钮方法 :>> ');
      this.change_page = 0;
      this.empty_ds = 1;
      const that = this;
      // 点击搜索发送请求,

      if (that.product_name === undefined) {
        that.product_name = '';
      }

      if (that.product_message === undefined) {
        that.product_message = '';
      }
      if (that.value1 === null || that.value1.length == 0) {
        that.value1 = ['', ''];
        console.log('that.value1 :>> ', that.value1);
      }
      this.searchStatus = false;
      HttpClient.post('program/searchlist', {
        user_id: that.$store.state.userid,
        project_name: that.product_name,
        startdate: that.value1[0],
        enddate: that.value1[1],
        productinfo: that.product_message,
        status: '已保存',
        page: this.pages,
        size: 10,
      }).then((res) => {
        this.empty_d = 0;
        console.log(res);

        this.sum = res.totalpage;
        this.choiceNum = res.totalpage;
        that.Res_Start_data = [];

        if (that.product_name != '' || that.value1[0] != '' || that.value1[1] != '' || that.product_message != '') {
          this.footName = '搜索到';
        } else {
          this.footName = '共';
        }

        // this.$set(this, "Res_Start_data", []);
        that.Res_Start_data = res;
        // this.$set(this, "Res_Start_data", res);

        // 重置 传递过来的数据 增加三个操作.
        for (let index = 0; index < that.Res_Start_data.result.length; index++) {
          that.Res_Start_data.result[index] = Object.assign(that.Res_Start_data.result[index], that.cz_arr);
        }
        if (that.Res_Start_data.result.length == 0) {
          this.empty_d = 1;
        }
        // 同步下方的 选择个数和 总体项目数量
        // this.choiceNum = this.Res_Start_data.result.length;
        console.log(that.Res_Start_data.result);
        // this.$EventBus.$emit('sendHavaProject_Num', this.choiceNum);
      });
    },
    // 重置按钮
    Reset() {
      this.empty_d = 0;
      this.change_page = 1;
      const that = this;
      that.product_message = '';
      that.value1 = [];
      that.product_name = '';
      this.searchStatus = true;
      that.fist_req();
    },
    // 初次加载页面的数据
    async fist_req() {
      // 页面加载完成 发送第一次请求初始数据展示,
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        upSort: this.sort_value,
        status: '已保存',
        page: this.pages,
        size: 10,
      }).then((res) => {
        // that.Res_Start_data = [];
        this.Res_Start_data = res;
        if(this.$store.state.lang== "cn"){
           if(this.Res_Start_data){
            this.Res_Start_data.result.forEach((item,index)=>{
                if(item.category=="Suncreen"){
                    this.Res_Start_data.result[index].category = "防晒霜"
                }
            })
        }
        }else{
          if(this.Res_Start_data){
            this.Res_Start_data.result.forEach((item,index)=>{
                if(item.category=="Suncreen"||item.category=="防晒霜"){
                    this.Res_Start_data.result[index].category = "Sun care"
                }
            })
        }
        }
       
        this.sum = res.totalpage;
        this.choiceNum = res.number;
        this.footName = '共';
        console.log(res);
        console.log(res.length);
        console.log(this.Res_Start_data);
        // 重置 传递过来的数据 增加三个操作.
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.Res_Start_data.result[index] = Object.assign(this.Res_Start_data.result[index], this.cz_arr);
        }
        if (this.Res_Start_data.result.length != 0) {
          this.empty_ds = 1;
        } else {
          this.empty_ds = 0;
        }
        // 同步下方的 选择个数和 总体项目数量
        // this.choiceNum = this.Res_Start_data.result.length;
        // console.log(this.Res_Start_data.result);

        // 所有多选框未被选中
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.allOne[index] = false;
        }
      });

      // 用来请求左侧已有项目导航栏数量的
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '已保存',
        page: 0,
        size: 999,
      }).then((res) => {
        console.log(res);
        this.choiceNum = res.result.length;
        this.$EventBus.$emit('sendHavaProject_Num', this.choiceNum);
        console.log(this.total_);
      });
    },

    async sort_list() {
      this.sort_value = !this.sort_value;
      // 页面加载完成 发送第一次请求初始数据展示,
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: this.product_name,
        startdate: this.value1[0],
        enddate:  this.value1[1],
        productinfo: this.product_message,
        upSort: this.sort_value,
        status: '已保存',
        page: this.pages,
        size: 10,
      }).then((res) => {
        // that.Res_Start_data = [];
        this.Res_Start_data = res;
        this.sum = res.totalpage;
        this.choiceNum = res.number;
        console.log(res);
        console.log(res.length);

        // 重置 传递过来的数据 增加三个操作.
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.Res_Start_data.result[index] = Object.assign(this.Res_Start_data.result[index], this.cz_arr);
        }
        if (res.result.length != 0) {
          this.empty_ds = 1;
        } else {
          this.empty_ds = 0;
        }
        // 同步下方的 选择个数和 总体项目数量
        // this.choiceNum = this.Res_Start_data.result.length;
        // console.log(this.Res_Start_data.result);

        // 所有多选框未被选中
        for (let index = 0; index < this.Res_Start_data.result.length; index++) {
          this.allOne[index] = false;
        }
      });

      //用来请求数量的
      // await HttpClient.post('program/searchlist', {
      //   user_id: this.$store.state.userid,
      //   project_name: '',
      //   startdate: '',
      //   enddate: '',
      //   productinfo: '',
      //   status: '已保存',
      //   page: 0,
      //   size: 999,
      // }).then((res) => {
      //   console.log(res);
      //   this.choiceNum = res.result.length;
      //   // this.$EventBus.$emit('sendHavaProject_Num', this.choiceNum);
      //   console.log(this.total_);
      // });
    
    },

    //删除确认按钮i单个
    async delete_one() {
      this.Once_sureProject = 0;
      // 请求删除数据
      await HttpClient.post('program/delete', {
        id: this.Res_Start_data.result[this.delete_index].programid,
      }).then((res) => {
        // 删除返回信息
        console.log(res);
      });
      // 重新加载
      this.fist_req();
    },
    //删除确认按钮，多选删除
    async deletes_end() {
      this.Once_sure = 0;
      // const delete_i = [];
      for (let index = 0; index < this.remember_choose.length; index++) {
        await HttpClient.post('program/delete', {
          id: this.remember_choose[index],
        }).then((res) => {
          // 删除返回信息
          console.log(res);
          this.allin = false;
        });
      }
      this.remember_choose = [];
      // 重新加载
      this.fist_req();
    },
    //日期选择器清空之后的操作。
    pickerChangeFn(value) {
      // 日期选择器改变事件 ~ 解决日期选择器清空 值不清空的问题
      if (value === null) {
        // this.parms.beginTime = value
        this.fist_req();
        // this.parms.endTime = value
      }
    },

    handleRemoves(file, fileList) {
      console.log(file, fileList);
    },
    beforeUpload(file) {
      // if (file.size / (1024 * 1024) > 500) {  // 限制文件大小
      //   message.warning(`当前限制文件大小不能大于500M`)
      //   return false
      // }

      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ['xlsx']; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        message({
          showClose: true,
          message: '请上传正确格式的项目文件',
          type: 'error',
          duration: 2000,
        });
      }
      return suffixArray;
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.');
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return '';
      }
    },

    handlePreviews(file) {
      console.log(file);
    },
    handleExceeds(files, fileList) {
      message.warning(`当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemoves(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //note上传返回信息
    async uploadFiles(params) {
      const file = params.file;
      const form = new FormData();
      form.append('file', file);
      console.log(params);
      form.append('program', this.Res_ID.programid);
      await HttpClient.post('program/programData_upload1', form, {
        headers: { 'Conten-Type': 'multipart/form-data' },
      }).then((res) => {
        console.log(res);
        this.replace = res;
        this.products_val = res.result.productArray;
        if (res.status == 1) {
          // message.warning();
          message({
            type: 'warning',
            showClose: true,
            message: res.message,
          });
          return;
        }
        if (res.status == 0) {
          console.log('上传成功');
          if (this.replace.result.project_name == this.Res_Start_data.result[this.open_index].program_name) {
            this.upload_success = false; //关闭上传页面
            this.req_Renew();
          } else {
            //验证项目名是否唯一
            HttpClient.post('program/isunique', {
              user_id: this.$store.state.userid,
              project_name: res.result.project_name,
            }).then((res) => {
              // console.log(res);
              //项目名唯一
              if (res.isunique == true) {
                this.upload_success = false; //关闭上传页面
                this.req_Renew();
              } else {
                //项目名不唯一
                message({
                  showClose: true,
                  message: '项目编号格式有误或已重复，请修改后再次上传',
                  type: 'warning',
                });
              }
            });
          }
        }
      });
    },

    //上传文件,项目名称校验成功才可调用
    async req_Renew() {
      //NOTE上传成功->请求替换项目信息.

      await HttpClient.post('program/add', {
        updates: {
          _id: this.Res_ID.programid,
          project_name: this.replace.result.project_name, //项目编号
          category: this.replace.result.category, //产品品类
          status: '已保存', // 项目状态
          // panel_files: "", //感观数据url地址
          equipment_files: '', //仪器数据url地址
        },
      }).then((res) => {
        console.log(res);
      });

      //NOTE更改项目内的产品信息(先删除,在新增)
      //删除产品信息
      for (let x = 0; x < this.old_product_id_list.length; x++) {
        await HttpClient.post('ctmproduct/delete', {
          id: this.old_product_id_list[x],
        }).then((res) => {
          console.log('删除产品信息', res);
        });
      }

      //增加产品信息
      for (let index = 0; index < this.products_val.length; index++) {
        //增加产品接口
        await HttpClient.post('ctmproduct/add', {
          updates: {
            name: this.products_val[index].name, //产品名称
            classification: this.products_val[index].source, //产品来源
            category: this.products_val[index].category, //产品品类
            product_number: this.products_val[index].product_number, //产品编号
            color: this.products_val[index].color, //产品颜色
            product_place: this.products_val[index].product_place, //产地
            brand: this.products_val[index].brand, //品牌
            quality: this.products_val[index].quality, //质地
            remark: this.products_val[index].remark, //备注
            isrecommend: true,
            Program: this.Res_ID.programid, //项目
          }, //
        }).then((res) => {
          console.log('增加产品信息', res);
        });
      }

      await HttpClient.post('ctmproduct/addProduct_result', {
        id: this.Res_ID.programid,
      }).then((res) => {
        console.log(res);
      });
      // this.opens(this.open_index)
      this.fist_req();
    },
  },
  created() {
    // 接收返回
    this.$EventBus.$on('back_haveproject', (data) => {
      console.log(data);
      if (data == 'Haveproject') {
        console.log('返回我的项目');
        this.diss = 0;
        this.fist_req();
      }
    });
  },
  mounted() {
    //发送第一此请求数据
    this.fist_req();
  },
  beforeDestroy() {
    this.$EventBus.$off('back_haveproject');
    this.$store.commit('$_addShowProgram', '');
  },
};
</script>
<style>
div.tooltipStyless {
  opacity: 0.8;
  width: 541px;
}
</style>

<style lang="less" scoped>
@import url("../../style/Project/HaveProject.less");
</style>
