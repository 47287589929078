<template>
  <!-- 我的项目默认显示组件 -->
  <div id="MyPcontent">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <div
        class="Content"
        v-if="diss == 0"
      >
        <!-- <p @click="change_psw">{{ $t(`message.Project.Fast`) }}</p> -->
        <p>{{ $t(`message.Project.Fast`) }}</p>
        <!-- 项目分类  新建  已有-->
        <div
          class="project-type"
          v-show="$store.state.lang == 'cn'"
        >
          <div
            class="NewProject"
            @click="comName"
          >
            <img
              src="https://newstore.vynior.com/htpj7.png"
              alt=""
            />
          </div>
          <div
            class="OldProject"
            @click="changes"
          >
            <img
              src="https://newstore.vynior.com/htpj8.png"
              alt=""
            />
          </div>
        </div>
        <div
          class="project-type"
          v-show="$store.state.lang == 'en'"
        >
          <div
            class="NewProject"
            @click="comName"
          >
            <img
              src="https://newstore.vynior.com/xjxjxj.png"
              alt=""
            />
          </div>

          <div
            class="OldProject"
            @click="changes"
          >
            <img
              src="https://newstore.vynior.com/drdrdr.png"
              alt=""
            />
          </div>
        </div>
        <div class="recently">
          <div class="title">{{ $t(`message.Project.recently`) }}</div>
        </div>
        <!-- <p>最近</p> -->

        <div class="Detail">
          <div class="title">
            <div class="One">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t(`message.Project.recentlyOpne`) }}</span>
            </div>
            <div class="Two">
              <span>{{ $t(`message.Project.number`) }}</span>
            </div>
            <div class="Three">
              <span>{{ $t(`message.Project.CreationTime`) }}</span>
            </div>
            <div class="Fore">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ $t(`message.Project.QuantityProduct`) }}</span>
            </div>

            <div class="Five">
              <span>{{ $t(`message.Project.ProductName`) }}</span>
            </div>

            <div class="Six">
              <span>{{ $t(`message.Project.operation`) }}</span>
            </div>
          </div>
          <!-- 循环数据 -->
          <div class="SuchData">
            <div
              class="croDate"
              :class="[index === myProject.length - 1 ? 'displayLine' : '']"
              v-for="(item, index) in myProject"
              :key="index"
            >
              <div class="content_box">
                <div class="date_project_date">
                  <span> {{ item.showtime }}</span>
                </div>

                <div class="date_id">
                  <div style="width: auto; max-width: 159px">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.project_name"
                      placement="top"
                      :open-delay="500"
                      popper-class="back_ground"
                    >
                      <span> {{ item.project_name }}</span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="data_time">
                  <span> {{ item.createdtime }}</span>
                </div>
                <div class="date_num">
                  <span> {{ item.productsnumber }}</span>
                </div>
                <div class="date_names">
                  <div>
                    <el-tooltip
                      :open-delay="500"
                      class="item"
                      effect="dark"
                      :content="item.productsnamelist.toString()"
                      placement="top"
                      popper-class="Content_max"
                    >
                      <span style="max-width: 607px; display: inline-block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                        {{ item.productsnamelist.toString() }}</span>
                    </el-tooltip>
                  </div>
                </div>
                <span
                  class="date_cz0"
                  @click="goCheck(index)"
                > {{ (item.cz0 = $t(`message.Project.view`)) }}</span>
                <span
                  class="date_cz1"
                  @click="opens(index)"
                > {{ (item.cz1 = $t(`message.Project.edit`)) }}</span>
                <span
                  class="date_cz2"
                  @click="toItem(index)"
                > {{ (item.cz2 = $t(`message.Project.forecast`)) }}</span>
                <span
                  class="date_cz3"
                  @click="singleDerive(index)"
                > {{ (item.cz3 = $t(`message.Project.derive`)) }}</span>
                <span
                  class="date_cz4"
                  @click="check_delte(index)"
                > {{ (item.cz4 = $t(`message.Project.remove`)) }}</span>
              </div>
            </div>
          </div>

          <!-- 账号下面空项目 -->
          <div
            class="empty_datas"
            v-show="empty_ds"
          >
            <div class="img">
              <img
                src="https://newstore.vynior.com/kong_data.png"
                alt=""
              />
            </div>
            <div class="message">{{ $t(`message.Project.empty`) }}</div>
          </div>
        </div>
      </div>
      <!-- 修改数据页面 -->
      <ChangeProject
        v-if="diss == 1"
        @toHave="toHave"
      />

      <!-- 已有项目页面 -->
      <HaveProject v-if="diss == 2" />

      <!-- 新建项目页面 -->
      <CreateProject v-if="diss == 3" />
      <!-- 移除具体项目操作 -->
      <div
        class="notarize_delete"
        v-show="Once_sureProject"
      >
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div
              class="line_1"
              :class="Once_sureProject == 1 ? 'changeColor' : ''"
            >
              <div class="text">
                {{ $t(`message.Project.Remove`) }}
              </div>

              <img
                src="https://newstore.vynior.com/xxx.png"
                alt=""
                @click="Once_sureProject = 0"
              />
            </div>
            <div class="line_2">
              <img
                src="https://newstore.vynior.com/gth.png"
                alt=""
              />
              {{ $t(`message.Project.Removeit`) }}
            </div>
            <div class="line_3">
              <span
                class="cancle"
                @click="Once_sureProject = 0"
              >{{ $t(`message.Project.Cancel`) }}</span>
              <span
                class="sure"
                @click="check_remove"
              >{{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 修改密码的页面 -->
      <div
        class="change_psw"
        v-show="change_"
      >
        <div class="content">
          <div class="line1">
            <span>{{ $t(`message.Project.Reset`) }}</span>
            <img
              src="https://newstore.vynior.com/can1.png"
              alt=""
              @click="go_quit"
            />
          </div>
          <div class="line2">{{ $t(`message.Project.safety`) }}</div>
          <div class="line3">
            <span class="line3-text">{{ $t(`message.Project.New`) }}</span>
            <el-input
              :placeholder="$t(`message.Project.Enter`)"
              v-model="psw_one"
              @focus="pswTips = true"
              @blur="pswTips = false"
              show-password
              class="input_password"
            ></el-input>
            <!-- <input type="password" placeholder="请输入新密码" class="psw1" v-model="psw_one" /> -->
          </div>

          <!-- <div class="line4" v-show="0">
            <span>密码长度8位，不可包含账号，必须包含字母、数字、符号两种</span>
          </div> -->
          <div class="line5">
            <span class="line5-text">{{ $t(`message.Project.Confirm`) }}</span>
            <el-input
              :placeholder="$t(`message.Project.Confirms`)"
              v-model="psw_two"
              show-password
              class="input_password"
            ></el-input>

            <!-- <input type="password" placeholder="" class="psw2" v-model="psw_two" /> -->
          </div>

          <div
            class="line6"
            v-show="$store.state.lang == 'cn'"
          >
            <img
              src="https://newstore.vynior.com/backhome.png"
              alt=""
              class="back_home"
              @click="go_quit"
            />
            <img
              src="https://newstore.vynior.com/queren.png"
              alt=""
              class="queren"
              @click="change_sure"
            />
          </div>
          <div
            class="line6"
            v-show="$store.state.lang == 'en'"
          >
            <img
              src="https://newstore.vynior.com/backkks.png"
              alt=""
              class="back_home"
              @click="go_quit"
            />
            <img
              src="https://newstore.vynior.com/savesssave.png"
              alt=""
              class="queren"
              @click="change_sure"
            />
          </div>
          <!-- 要修改的提醒弹窗 -->
          <div
            class="password_tip"
            v-show="pswTips && $store.state.lang == 'cn'"
          >
            <div class="text_content">
              8位密码长度 <br />
              至少包含数字、大小写字母及特殊字符中的两种 <br />
              不使用相同的数字或字符，如111111、aaaaaa<br />
              不使用连续升序或降序的数字或字母，如123456、abcdef<br />
              <!-- 不使用身份证后8位作为密码<br /> -->
              密码不包含账号
            </div>
          </div>
          <div
            class="password_tip"
            v-show="pswTips && $store.state.lang == 'en'"
          >
            <div class="text_content">
              Password must<br />
              - Be at least 8 characters in length <br />
              - Include at least two of the following elements: <br />
              At least one letter (upper or lowercase)<br />
              At least one number<br />
              At least one special character (!"#$%&' ()*+,-./:;<=>?@[]^_` {|}~)<br />
                <!-- 不使用身份证后8位作为密码<br /> -->
                - Not include more than 2 identical characters (e.g. 11111,aaaaa) - Not include more than 2 consecutive 2 identical characters (e.g.
                123, abc) <br />
                - Not include your user name<br />
            </div>
          </div>
        </div>
      </div>
      <!-- 修改密码成功界面 -->
      <div
        class="change_success"
        v-show="success_"
      >
        <div class="content2">
          <div class="line1">
            <span>{{ $t(`message.Project.Reset`) }}</span>
          </div>
          <div class="line2">
            <img
              src="https://newstore.vynior.com/success.png"
              alt=""
              class="susccess"
            />
            <span class="pswsuc">{{ $t(`message.Project.successfully`) }}</span>
            <span
              class="num_"
              v-show="$store.state.lang == 'cn'"
            ><span class="num">{{ count }}秒</span>后自动跳转至首页重新登录</span>
            <span
              class="num_"
              v-show="$store.state.lang == 'en'"
            >Return to login page in <span class="num">{{ count }} </span>seconds</span>
            <img
              src="https://newstore.vynior.com/backhome.png"
              alt=""
              class="back_home"
              @click="go_quit"
              v-show="$store.state.lang == 'cn'"
            />
            <!-- <button v-show="$store.state.lang == 'en'">Return to login</button> -->
            <img
              src="https://newstore.vynior.com/backLogin.png"
              alt=""
              class="back_home"
              @click="go_quit"
              v-show="$store.state.lang == 'en'"
            />
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
import { HttpClient } from '@/utils/http';

import ChangeProject from './ChangeProject.vue'; //修改页
import HaveProject from './HaveProject.vue'; //已有页面
import CreateProject from './CreateProject.vue'; //新建页面
import getFilename from '../../utils/getFilename';
export default {
  name: 'MyPcontent',
  components: { ChangeProject, CreateProject, HaveProject },
  data() {
    return {
      pswTips: false,
      psw_two: '', //重置密码
      psw_one: '', //重置密码
      pwd: '',
      empty_ds: 0,
      timer: null, //设置计时器,
      count: '',
      num_1: 6,
      success_: 0,
      change_: 0,
      remove_index: -1, //储存移除的项目下标
      // 存储已有项目的数量
      HaveNum: 0,
      DraftNum: 0,
      //删除具体项目
      Once_sureProject: 0,
      // 项目名称
      project_name: '',
      // 控制再次确认的值。
      Once_sure: 0,

      Res_program: [], //
      cz_arr: { cz1: '编辑', cz2: '预测', cz3: '导出', cz4: '移除', cz0: '查看' },
      myProject: [], // 我的项目--已有项目数据
      newProject: [],
      diss: 0, //页面默认为显示已有项目
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, //获取浏览器高度
      
    };
  },
  watch: {
    success_(newVal) {
      if (newVal == true) {
        this.num_reduce();
      }
    },
  },
  methods: {
    // !  NOTE单个导出方法
    async singleDerive(index) {
      let programlist = [this.myProject[index].programid];
      // console.log("programlist :>> ", programlist);
      let response = await HttpClient.post(
        'program/derive',
        {
          programlist,
          language:this.$store.state.lang
        },
        { responseType: 'arraybuffer' }
      );
      let name = getFilename(response);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      let downloadElement = document.createElement('a');
      let oneProjecthref = window.URL.createObjectURL(blob);
      downloadElement.href = oneProjecthref;
      downloadElement.download = `${name}`;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(oneProjecthref);
      // console.log("name :>> ", name);
      // console.log("response :>> ", response);
    },
    // !  导出的界面显示
    //修改密码 确认按钮
    async change_sure() {
      if (this.psw_one != this.psw_two) {
        this.$message({
          showClose: true,
          message: this.$t(`message.Message.Passwordmust`),
          type: 'warning',
        });
      } else {
        // var reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/);

        // var reg = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/);

        var reg = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9A-Za-z~!@#$%^&*._?]{8}$/);

        if (!reg.test(this.psw_one)) {
          this.$message({
            showClose: true,
            message: this.$t(`message.Message.Passwordmust`),
            type: 'warning',
          });
        } else {
          console.log('符合');
          this.change_ = 0;
          this.success_ = 1;
          await HttpClient.post('subuser/update', {
            type: '修改密码',
            updates: {
              _id: this.$store.state.userid,
              password: this.psw_one,
            },
          }).then((res) => {
            console.log(res);
          });
        }
      }
    },
    //回到首页
    back_homes() {
      this.$router.push({
        path: '/',
      });
    },
    // 控制修改密码隐藏
    change_psw() {
      console.log(this.height);
      this.change_ = !this.change_;
    },
    // 计时关闭
    num_reduce() {
      // 60s倒计时
      let TIME_COUNT = 5;
      if (!this.timer) {
        this.count = TIME_COUNT;
        // this.isGrey = true;
        // this.show = false;
        // this.dis = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else if (this.count == 0) {
            console.log('00000000');
            this.success_ = 0;
            clearInterval(this.timer);
            this.timer = null;
            this.go_quit();
          }
        }, 1000);
      }
    },

    toHave() {
      this.diss = 2;
    },
    // 点击前往修改项目信息页面
    async opens(i) {
      console.log(11111, i);
      this.diss = 1;
      await HttpClient.post('program/open1', {
        id: this.myProject[i].programid,
      }).then((res) => {
        this.Res_program = res;
        let fileName = this.Res_program.program.panel_name;
        // console.log('fileName :>> ', fileName);
        this.$EventBus.$emit('sendProgram', this.Res_program);
        this.$store.commit('$_change_backFrom', 1); //更改返回路径
        this.$EventBus.$emit('fileName', fileName);
      });

      //点击修改
      let page = 'Haveproject';
      this.$EventBus.$emit('send_changepage', page);
    },
    comName() {
      this.diss = 3;
      this.$EventBus.$emit('pageChange', 'CreateProject');
      this.$store.commit('$_change_upload', true);
    },

    // 新建项目的显示隐藏
    changes() {
      // 点击修改
      this.diss = 2;
      this.$EventBus.$emit('pageChange', 'HaveProject');
    },
    toItem(val) {
      this.$router.push({
        name: 'itemanalysis',
        params: {
          project_name: this.myProject[val].project_name,
        },
      });
      // this.$EventBus.$emit('Toitem', 'toItem');
    },
    // 移除操作
    check_delte(index) {
      // 给删除操作赋值名称;
      // this.project_name = this.myProject[index].project_name;
      this.Once_sureProject = 1;
      this.remove_index = index;

      console.log(this.remove_index);
      console.log('我要移除他');
      console.log(this.myProject[index]);
    },
    // 确认移除字后请求移除方法"program/remove"
    async check_remove() {
      console.log(this.remove_index);
      let i = this.remove_index;
      await HttpClient.post('program/remove', {
        id: this.myProject[i].programid,
      }).then((res) => {
        console.log(res);
      });
      this.Once_sureProject = 0;
      this.fist_req();
    },

    // 初次请求后台展示数据方法
    async fist_req() {
      const _this = this;
      // // 点击我的项目,发送请求,获取当前用户已有项目的数据
      await HttpClient.post('program/myfind', {
        language: this.$store.state.lang,
        id: _this.$store.state.userid,
        // language:this.$store.state.lang,
      }).then((res) => {
        // 请求结束
        // 重置 传递过来的数据 增加三个操作.
        _this.myProject = res.programlist.slice(0, 8);
        console.log(_this.myProject);
        for (let index = 0; index < _this.myProject.length; index++) {
          _this.myProject[index] = Object.assign(_this.myProject[index], _this.cz_arr);
        }
      });
      if (_this.myProject.length == 0) {
        _this.empty_ds = 1;
      } else {
        _this.empty_ds = 0;
      }
      // console.log(this.$store.state.userid);

      console.log(_this.myProject);
    },

    // 初次加载已有项目页面的数据
    async HaveNum_req() {
      // 页面加载完成 发送第一次请求初始数据展示,
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '已保存',
        page: 0,
        size: 999,
      }).then((res) => {
        // that.Res_Start_data = [];
        this.Res_Start_data = res.result.length;

        // 同步下方的 选择个数和 总体项目数量
        this.HaveNum = this.Res_Start_data;
        console.log(this.Res_Start_data.result);
        this.$EventBus.$emit('sendHavaProject_Num', this.HaveNum);
      });
    },

    // 初次加载页面的数据
    async DraftNum_req() {
      // 页面加载完成 发送第一次请求初始数据展示,
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '草稿箱',
        page: 0,
        size: 999,
      }).then((res) => {
        // that.Res_Start_data = [];
        // this.Res_Start_data = res;
        this.Res_Start_data = res.result.length;
        // 同步下方的 选择个数和 总体项目数量
        this.DraftNum = this.Res_Start_data;
        console.log(this.Res_Start_data.result);
        this.$EventBus.$emit('sendHavaDrafts_Num', this.DraftNum);
      });
    },
    go_quit() {
      this.$router.push({ path: '/home' });
      this.Quit_id.quit();
    },
    //点击查看前往check页面
    async goCheck(i) {
      // console.log(11111, i);

      await HttpClient.post('program/open1', {
        id: this.myProject[i].programid,
      }).then((res) => {
        this.$store.commit('$_addShowProgram', res);
        // console.log('Res_program :>> ', this.Res_program);
        this.$store.commit('$_change_backFrom', 1); //更改返回路径
        this.$EventBus.$emit('changeCheck', 'Check');
        // this.$EventBus.$emit('takeOut', this.Res_program);
      });
      //点击修改
      let page = 'Check';
      this.$EventBus.$emit('send_checkpage', page);
    },
  },
  created() {
    // 监听 中央事件总线 获取header传过来的数据
    // this.$EventBus.$on("sendmyProject", (data) => {
    //   this.myProject = data;
    //   console.log(this.myProject[0].productsnamelist);
    // });
    this.$EventBus.$on('back_haveprojects', (data) => {
      console.log(data);
      if (data == 'Haveproject') {
        console.log('返回我的项目');
        this.diss = 0;
        // this.fist_req();
        this.$EventBus.$emit('change_click', 'yes');
      }
    });
  },
  mounted() {
    this.diss = 0;
    // 调用方法
    this.fist_req();
    this.HaveNum_req();
    this.DraftNum_req();
    // 加载已有项目个数
    this.pwd = this.$route.query.pwd;
    console.log(this.$route.query.pwd);
    if (this.pwd == 'welcome123') {
      this.change_ = !this.change_;
    }
  },
  beforeDestroy() {
    // 结束监听 中央事件总线
    this.$EventBus.$off('sendmyProject');
  },
};
</script>

<style lang="less">
@import url("../../style/Project/MyPcontent.less");
</style>
